import React from 'react';
import {FormFeedback, FormGroup, Label} from "@bootstrap-styled/v4";
import type {FieldProps} from 'redux-form';
import styled from "styled-components";
import {Select} from "../components-v3";

const StyledLabel = styled(Label)`
    font-weight: ${props => props.theme['$label-font-weight']};
    text-transform: uppercase;
`;

const StyledFormGroup = styled(FormGroup).attrs(props => ({
    className: 'd-flex flex-column my-3',
    ...props
}))``;

export default ({ input, meta, label, formGroup, ...rest }: FieldProps) => {
    return <StyledFormGroup color={meta.touched && meta.error && 'danger'} {...formGroup}>
        {label && <StyledLabel>{label}</StyledLabel>}
        <Select {...input} {...rest}/>
        {meta.touched && meta.error && <FormFeedback>{meta.error}</FormFeedback>}
    </StyledFormGroup>;
}