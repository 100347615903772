//@flow

import * as React from 'react';
import {connect} from 'react-redux';

import ProjectForm from './ProjectForm';
import {createProject} from '../../queries';
import {Flex} from '../../components';
import styled from "styled-components";

const StyledProject = styled(({selected, ...rest}) => <Flex {...rest}/>).attrs({className: 'px-5'})`
    background: ${props => props.theme['$seashell']}
`;


type CreateProjectProps = {
    save: () => any,
    onReset: () => void
}

class CreateProject extends React.PureComponent<CreateProjectProps> {

    render() {
        return <StyledProject flexDirection="column" flexShrink={0}>
            <ProjectForm {...this.props}/>
        </StyledProject>
    }
}

type StateProps = {
    form: string
}

type DispatchProps = {
    save: (values: {}) => Promise<any>
}

type OwnProps = {

}

type Props = {
    ...OwnProps,
    ...StateProps,
    ...DispatchProps
}

type ConnectedCreateProjectProps = {
    onCreate: () => void
}

export default connect<Props, OwnProps, StateProps, DispatchProps, _, _>(
    (state, props: ConnectedCreateProjectProps) => {
        return {
            form: 'project'
        }
    },
    (dispatch, props: ConnectedCreateProjectProps) => {
        return {
            save: values => dispatch(createProject(values))
                .then(props.onCreate)
        }
    }
)(CreateProject);
