//@flow
import * as React from "react";
import {connect} from "react-redux";
import styled from 'styled-components';

import ArticlesList from "./ArticlesList";
import ArticleDetail from "./ArticleDetail";
import {clear} from "../../../actions/graphql";
import {Flex} from '../../../components';
import Empty from './Empty';
import {Modal, ModalBody, ModalHeader} from '@bootstrap-styled/v4';
import article from '../../../decorators/article';
import MediaQuery from 'react-responsive';
import ResultBar from "../ResultBar";

const StyledContent = styled(Flex)`
`;

class Articles extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            article: null
        }
    }

    componentDidMount() {
        const { clearArticle } = this.props;

        clearArticle();
    }

    handleSelect = article => {
        this.setState({article: article});
    };

    handleClose = () => {
        this.setState({article: null})
    };

    render(): Node {
        const { isFetching, count } = this.props;

        if (!isFetching && (0 === count)) {
            return <Empty />
        }

        return <StyledContent flex={1} flexDirection="column">
            <ResultBar onSelectArticle={this.handleSelect}/>
            <Flex flex={1}>
                <Flex flex={1} className="articles-list">
                    <ArticlesList selected={this.state.article} onSelect={this.handleSelect}/>
                </Flex>
                <MediaQuery minDeviceWidth={768}>
                    <Flex flex={1} className="p-3 d-none d-md-flex">
                        <ArticleDetail article={this.state.article}/>
                    </Flex>
                </MediaQuery>
                <MediaQuery maxDeviceWidth={767}>
                    <Modal isOpen={this.state.article} toggle={this.handleClose}>
                        <ModalHeader toggle={this.handleClose}>Detail</ModalHeader>
                        <ModalBody>
                            <Flex flex={1} className="p-3">
                                <ArticleDetail article={this.state.article}/>
                            </Flex>
                        </ModalBody>
                    </Modal>
                </MediaQuery>
            </Flex>
        </StyledContent>
    }
}

export default connect(
    state => {
        return {
            isFetching: state.search.isFetching,
            count: state.search.meta.count
        }
    },
    dispatch => {
        return {
            clearArticle: () => dispatch(clear('article'))
        }
    }
)(Articles);
