import React, { PureComponent } from 'react';
import {Field, reduxForm, SubmissionError} from 'redux-form';
import { withRouter } from 'react-router-dom';
import {Button, Form, FormFeedback, H1, Row, Col, Container} from '@bootstrap-styled/v4';

import {Flex} from '../../components';
import {Logo} from "../../components-v3";
import styled from "styled-components";
import InputField from "../../fields/InputField";
import {login} from "../../queries";

const App = styled(Flex)`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
`;

const StyledLogin = styled(Flex).attrs({flex: 1})`
    background: url('${require('../../assets/login.jpg')}') 0 0/cover;
`;

const StyledH1 = styled(H1).attrs({className: 'mb-3'})`
    color: ${props => props.theme['$white']};
`;

class Login extends PureComponent {

    render() {
        const { handleSubmit, error } = this.props;

        return <App flexDirection="column">
            <Row className="px-5 py-2 border-bottom justify-content-between align-items-center">
                <Logo />
            </Row>
            <StyledLogin flexDirection="column" className="justify-content-center align-items-center">
                <Form>
                    <StyledH1>Prihlásenie</StyledH1>
                    {error && <FormFeedback className="text-danger mb-3">{error}</FormFeedback>}
                    <Field name="email" component={InputField} type="text" placeholder="Prihlasovacie meno"/>
                    <Field name="password" component={InputField} type="password" placeholder="Heslo"/>
                    <Button onClick={handleSubmit}>Prihlásiť</Button>
                </Form>
                {/*<Flexbox xs={{flex: 'column', 'justify-content': 'between'}} lg={{flex: 'row'}}>*/}
                {/*    <div className="gray-500 order-2 order-lg-1">&copy; {(new Date()).getFullYear()} sixmedia s. r. o., SITA Slovenská tlačová agentúra a.s.</div>*/}
                {/*    <div className="motto text-uppercase order-1 order-lg-2 text-lg-right">Budťe <span className="invert">In</span>formovaný</div>*/}
                {/*</Flexbox>*/}
            </StyledLogin>
        </App>
    }
}
const LoginForm = reduxForm({
    form: 'login',
    onSubmit: (values, dispatch, props) => {
        return dispatch(login({
            username: values.email,
            password: values.password
        }))
            .then(() => {
                props.history.push('/new')
            })
        .catch(errors => {
            throw new SubmissionError({_error: 'Nesprávne prihlasovacie údaje'})
        });
    }
})(Login);

export default withRouter(LoginForm);
