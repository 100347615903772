import * as React from 'react';
import styled from 'styled-components';
import {Navbar, NavbarToggler, Nav, Collapse, NavItem} from "@bootstrap-styled/v4";

import {Logout, Help, Search, History, Projects} from './components';
import {Flex} from '../components';
import {Logo} from "../components-v3";

const Avatar = styled.img.attrs(props => ({
    className: 'rounded-circle mx-2',
    alt: 'avatar',
    src: 'f' === props.user?.gender ? require('../assets/img/female.png') : require('../assets/img/male.png')
}))`
    width: 30px;
    height: 30px;
`;

const Separator = styled(Flex).attrs({className: 'border-left mx-2 align-self-stretch'})`
`;

const StyledNavbar = styled(Navbar).attrs({ className: 'border-bottom' })`
    padding: ${props => props.theme['$spacers'][2]} !important;
    border-bottom: 1px solid ${props => props.theme['$silver']}
`;

const StyledNavbarToggler = styled(NavbarToggler)`
    span {
        background-image: url(${require('../assets/svg/hamburger.png')}) !important
    }
`;

const StyledNav = styled(Nav)`
    display: flex;
    flex: 1;
    justify-content: center;
`;

class Navigation extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            navbar: false
        }
    }

    toggleNavbar = () => {
        this.setState({navbar: !this.state.navbar})
    };

    render() {
        const { user } = this.props;

        return <StyledNavbar toggleable="sm">
            <Flex justifyContent="space-between" alignItems="center">
                <StyledNavbarToggler onClick={this.toggleNavbar}/>
                <Logo/>
            </Flex>
            <Collapse navbar={true} isOpen={this.state.navbar}>
                <StyledNav navbar={true}>
                    <NavItem>
                        <Search/>
                    </NavItem>
                    <NavItem>
                        <History/>
                    </NavItem>
                    <NavItem>
                        <Projects/>
                    </NavItem>
                </StyledNav>
                <Flex className="align-items-center">
                    <Avatar user={user}/>
                    <span className="mx-1">{user?.username}</span>
                    <Separator/>
                    <Help/>
                    <Logout/>
                </Flex>
            </Collapse>
        </StyledNavbar>
    }
}

export default Navigation;