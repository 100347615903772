import * as React from 'react';

type ArrayFieldProps = {
    value: [],
    onChange: () => void,
    option: number,
    component: any
}

export default class extends React.PureComponent<ArrayFieldProps> {

    render() {
        const { value = [], option, component, onChange, ...rest } = this.props;

        const Tag = component;

        return <Tag checked={-1 !== value.indexOf(option)} onChange={() => {
            const index = value.indexOf(option);
            if (-1 === index) {
                onChange([
                    ...value,
                    option
                ]);
            } else {
                onChange([
                    ...value.slice(0, index),
                    ...value.slice(index + 1)
                ]);
            }
        }} {...rest}/>;
    }
}
