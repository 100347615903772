// @flow

import * as React from 'react';
import {connect} from 'react-redux';

import {LineChart} from "../ChartTypes";
import {mentionsInTime} from "../../../queries";

class MentionsInTime extends React.PureComponent {

    componentDidMount() {
        const {getData} = this.props;

        getData();
    }

    render() {
        const {chart, data} = this.props;

        if (data.isFetching) {
            return null;
        }

        const series = data.items.map(item => [parseInt(item.key), parseInt(item.doc_count)]);
        return <LineChart chart={chart}
                          series={series}
                          title={chart.name}
        />
    }
}

MentionsInTime = connect(
    (state, props) => ({
        sources: state.sources,
        data: state.mentionsInTime
    }),
    (dispatch, props) => ({
        getData: () => dispatch(mentionsInTime({
            chart: props.chart.id,
            startAt: props.startAt,
            endAt: props.endAt
        }))
    })
)(MentionsInTime);

export default MentionsInTime;