import React, { Component } from 'react';
import { connect } from 'react-redux';
import gql from 'graphql-tag';
import styled from 'styled-components';

import { graphql } from '../actions';
import {Flex} from '../components';
import Navigation from "./Navigation";
import {getSourceGroups} from "../queries";

const App = styled(Flex)`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
`;

const Avatar = styled.img.attrs(props => ({
    className: 'rounded-circle mx-2',
    alt: 'avatar',
    src: 'f' === props.user?.gender ? require('../assets/img/female.png') : require('../assets/img/male.png')
}))`
    width: 30px;
    height: 30px;
`;

class Layout extends Component {

    componentDidMount() {
        const { getProfile, getSourceGroups } = this.props;

        getProfile();
        getSourceGroups();
    }

    render() {
        const { user, children } = this.props;

        return <App flexDirection="column" className="h-100 position-absolute">
            <Navigation />
            <Flex flex={1} flexDirection="column" className="flex-scroll-container">
                {children}
            </Flex>
            {/*<Footer className="px-5">*/}
            {/*    <Col>*/}
            {/*        <div className="ml-auto p-2 motto">*/}
            {/*            SEARCH <span id="version" className="invert">IN</span>*/}
            {/*            <Tooltip placement="top" target="version" color="cloudy-blue">{process.env.REACT_APP_VERSION}</Tooltip>*/}
            {/*        </div>*/}
            {/*    </Col>*/}
            {/*    <Col className="text-right">*/}
            {/*        <div className="m-1">All rights reserved. Copyright &copy; {(new Date()).getFullYear()}</div>*/}
            {/*    </Col>*/}
            {/*</Footer>*/}
        </App>;
    }
}

export default connect(
    state => {
        return {
            user: state.profile.data
        }
    },
    dispatch => {
        return {
            getSourceGroups: () => dispatch(getSourceGroups()),
            getProfile: () => dispatch(graphql('profile', 'profile', gql`query Profile {
                profile {
                    id
                    username
                    email
                }
            }`))
                    .then(response => {
                        window.fcWidget.setExternalId(response.payload.email);        // To set user name
                        // window.fcWidget.user.setFirstName(response.payload.firstname);
                        // window.fcWidget.user.setLastName(response.payload.lastname);
                        window.fcWidget.user.setEmail(response.payload.email);
                        window.fcWidget.user.setProperties({
                            application: 'searchin',
                            version: process.env.REACT_APP_VERSION
                        }
                    );
            })
        }
    }
)(Layout);