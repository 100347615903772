//@flow

import * as React from "react";

import Highcharts from './Highcharts';
import {Flex} from '../../../components';

class CloudChart extends React.PureComponent {

    render() {
        const {chart, title, series} = this.props;

        return <Highcharts chart={chart} options={{
            title: {
                text: title
            },
            series: [{
                type: 'wordcloud',
                data: series,
                name: 'Výskyt',
                rotation: false
            }]
        }}/>
    }
}

export default CloudChart;