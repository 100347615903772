//@flow

import * as React from 'react';
import styled from 'styled-components';

import Svg from './Svg';

export default styled(({name, active, hover, ...rest}) => <Svg name={name} {...rest} />)`
    background: transparent url('${props => props.active ? require(`../assets/svg/${props.name.replace('.', ' 2.')}`) : require(`../assets/svg/${props.name}`)}') no-repeat center center/100% content-box content-box;
    :hover {
        background-image: url('${props => props.hover ? props.hover : require(`../assets/svg/${props.name.replace('.', ' 3.')}`)}')
    }
`;
