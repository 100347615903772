import * as React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { Flex } from "../../components";
import {getSourceGroups, getSources} from "../../queries";
import SourceGroup from "./SourceGroup";
import SelectIcons from "./SelectIcons";
import sourceFilter from "./sourceFilter";
import {Input} from "@bootstrap-styled/v4";

const SourcesHeader = styled(Flex)`
    border-bottom: 2px solid ${props => props.theme['$silver']};
    padding-bottom: ${props => props.theme['$spacers'][3]};
    margin-bottom: ${props => props.theme['$spacers'][3]};
`;

const StyledSourceInnput = styled(Input)`
    padding-left: ${props => props.theme['$spacers'][2]};
    
    @media only screen and (min-width: 768px) {
        width: 25%;
    }
`;

const StyledSourceCount = styled.span.attrs({className: "text-mid-gray text-bigger mr-md-3"})`
    padding: 0 ${props => props.theme['$spacers'][2]};
`;

class Sources extends React.PureComponent {

    constructor(props) {
        super(props);

        this.state = {
            filter: null,
        }
    }

    componentDidMount() {
        const { getSources, getSourceGroups } = this.props;

        getSourceGroups();
        getSources();
    }

    handleClose = () => {
        const { onClose } = this.props;

        onClose();
    };

    handleSelectAll = (filter) => {
        const { selectAll } = this.props;

        selectAll(filter);
    };

    handleUnselectAll = () => {
        const { unselectAll } = this.props;

        unselectAll();
    };

    handleInvertSelection = () => {
        const { invertSelection } = this.props;

        invertSelection();
    };

    handleFilter = e => {
        this.setState({ filter: e.target.value });
    };

    render() {
        const { sourceGroups, sources, selected, input, onChange } = this.props;

        return <Flex flex={1} flexDirection="column" className="p-3">
            <SourcesHeader className="align-items-center justify-content-between">
                <Flex flex={1} alignItems="center">
                    <StyledSourceInnput placeholder="Zadajte názov zdroja" onChange={this.handleFilter}/>
                    <StyledSourceCount>{selected.length}/{sources.items.length}</StyledSourceCount>
                </Flex>
                <Flex>
                    <SelectIcons onSelect={() => this.handleSelectAll(this.state.filter)} onUnselect={this.handleUnselectAll} onInvert={this.handleInvertSelection}/>
                </Flex>
            </SourcesHeader>
            {Object.keys(sourceGroups).map((sourceGroup, index) => <SourceGroup key={index} input={input} selected={selected} label={sourceGroup} index={index} sourceGroup={sourceGroups[sourceGroup]} filter={this.state.filter} onChange={onChange}/>)}
        </Flex>
    }
}

export default connect(
    (state, props) => {
        const sourceGroups = [];
        state.sources.items.map(source => {
            if (undefined === sourceGroups[source.sourceGroup.name]) {
                sourceGroups[source.sourceGroup.name] = [];
            }
            sourceGroups[source.sourceGroup.name].push(source);
            return null;
        });

        return {
            sourceGroups: sourceGroups,
            sources: state.sources || [],
            selected: props.input.value,
            onChange: props.input.onChange
        }
    },
    (dispatch, props) => {
        return {
            getSources: () => dispatch(getSources()),
            getSourceGroups: () => dispatch(getSourceGroups()),
        }
    },
    (stateProps, dispatchProps, ownProps) => {
        return Object.assign({}, ownProps, stateProps, dispatchProps, {
            selectAll: (filter) => {
                return stateProps.onChange(stateProps.sources.items.filter(source => !sourceFilter(source, filter)).map(source => source.id))
            },
            unselectAll: () => stateProps.onChange([]),
            invertSelection: () => stateProps.onChange(stateProps.sources.items.map(source => source.id).filter(id => -1 === stateProps.selected.indexOf(id)))
        });
    }
)(Sources);
