import * as React from 'react';
import Select from 'react-select';
import styled from 'styled-components';

class SelectField extends React.PureComponent {

    handleChange = option => {
        const {onChange} = this.props;

        onChange(option.value);
    };

    get styles() {
        const {styles, theme} = this.props;

        return {
            control: (provided, state) => ({
                ...provided,
                borderRadius: 0,
                borderWidth: '2px',
                borderColor: theme['$silver'],
                minHeight: 0
            }),
            indicatorSeparator: () => ({
                display: 'none'
            }),
            dropdownIndicator: (provided, state) => ({
                ...provided,
                padding: '0 ' + theme['$spacers'][1]
            }),
            valueContainer: (provided, state) => ({
                ...provided,
                padding: '0 ' + theme['$spacers'][2],
                fontWeight: 300
            }),
            singleValue: ({transform, position, top, ...provided}, state) => ({
                ...provided,
                maxWidth: 'none'
            }),
            input: (provided, state) => ({
                ...provided,
                display: 'none'
            }),
            ...styles
        }
    }

    get value() {
        const {value, options} = this.props;

        return options.find(option => JSON.stringify(option.value) === JSON.stringify(value));
    }

    render() {
        const {...rest} = this.props;

        return <Select {...rest} value={this.value} onChange={this.handleChange} styles={this.styles} />
    }
}

export default styled(SelectField).attrs(props => ({
    theme: props.theme
}))``;