import React, { PureComponent } from 'react';
import { Button } from '@bootstrap-styled/v4';
import Tooltip from './Tooltip';

export default class Operator extends PureComponent {

    render() {
        const { id, operator, tooltip, color, onClick } = this.props;

        return <Button id={id} className="my-3 mx-2" size="sm" color={color} onClick={onClick}>
            <strong>{operator}</strong>
            <Tooltip target={id}>{tooltip}</Tooltip>
        </Button>
    }
}
