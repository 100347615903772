import React, { PureComponent, Node } from 'react';
import PropTypes from 'prop-types';

export default class extends PureComponent {

    static propTypes = {
        source: PropTypes.shape({
            name: PropTypes.string.isRequired
        }).isRequired
    };

    render(): Node {
        const { source } = this.props;

        if (!source) {
            return null;
        }

        return <span>{source.name}</span>
    }
}
