const BACKEND_URL = 'https://dev.searchin.sk';
// const BACKEND_URL = 'http://localhost:8080/public';
const GRAPHQL_URL_AUTHORIZED = `${BACKEND_URL}/graphql/authorized`;
const GRAPHQL_URL_ANONYMOUS = `${BACKEND_URL}/graphql/anonymous`;
const SSO_URL = 'https://sso.sixmedia.sk/graphql/';

const CLIPPING_URL_SMALL = 'https://search.sita.sk/media/cache/resolve/small/clippings/';
const CLIPPING_URL_MEDIUM = 'https://search.sita.sk/media/cache/resolve/medium/clippings/';
const CLIPPING_URL_LARGE = 'https://search.sita.sk/media/cache/resolve/large/clippings/';
const CLIPPING_URL = 'https://search.sita.sk/clippings/';

export {
    BACKEND_URL,
    GRAPHQL_URL_AUTHORIZED,
    GRAPHQL_URL_ANONYMOUS,
    SSO_URL,
    CLIPPING_URL,
    CLIPPING_URL_SMALL,
    CLIPPING_URL_MEDIUM,
    CLIPPING_URL_LARGE
};
