import {graphql} from "../actions";
import gql from "graphql-tag";

export default () => graphql('projects', 'projects', gql`query Project {
    projects {
        id
        name
        query
        sources {
            id
            name
        }
        startAt
        endAt
        absoluteStartAt
        absoluteEndAt
    }
}`)