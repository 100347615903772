import React from 'react';
import { compose, createStore, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
import { persistStore, persistReducer } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';
import { Route, Switch, Redirect, BrowserRouter as Router } from 'react-router-dom';

import MainLayout from './layouts/Main';
import reducers from './reducers';
import {Login, Logout, NewSearch, Help, SavedSearch, Projects, HistorySearch, History, Auth} from './pages';

import BootstrapProvider from '@bootstrap-styled/provider/lib/BootstrapProvider';
import theme from './theme';

const persistedReducer = persistReducer({ key: 'root', blacklist: ['ui', 'article', 'router'], storage }, reducers);
const store = createStore(persistedReducer, undefined, compose(applyMiddleware(thunk)));
const persistor = persistStore(store);

console.log('Version: ' + process.env.REACT_APP_VERSION);

export default () => (
    <BootstrapProvider theme={theme} injectGlobal={true}>
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <Router>
                    <Switch>
                        <Route path="/logout" component={Logout}/>
                        <Route path="/login" component={Login}/>
                        <Route path="/auth/:token" component={Auth}/>
                        <Route path="/" render={props => {
                            if (!store.getState().login.data) {
                                props.history.push('/login');
                            }
                            return <MainLayout>
                                <Switch>
                                    <Route path="/new" component={NewSearch}/>
                                    <Route path="/project/:id" component={SavedSearch}/>
                                    <Route path="/projects" component={Projects}/>
                                    <Route path="/history/:id" component={HistorySearch}/>
                                    <Route path="/history" component={History}/>
                                    <Redirect to="/new" />
                                </Switch>
                                <Route render={({match, location, history}) => 'help' === location?.state?.modal && <Help/>}/>
                            </MainLayout>
                        }}>
                        </Route>
                    </Switch>
                </Router>
            </PersistGate>
        </Provider>
    </BootstrapProvider>
);

