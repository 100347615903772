//@flow

import * as React from 'react';
import {connect} from 'react-redux';
import styled from 'styled-components';
import {formValueSelector} from 'redux-form';

import {Flex, Tooltip} from "../../components";
import {Button, Modal, ModalBody, ModalHeader, H3} from "@bootstrap-styled/v4";
import CreateChart from "./CreateChart";
import Charts from "./Charts";
import {MenuItem, SvgButton} from "../../components-v3";

const StyledHeader = styled(Flex)`
    padding: ${props => props.theme['$spacers'][2]};
`;

const FlippedSvgButton = styled(SvgButton)`
    transform: scaleY(-1);
`;

type AnalysisProps = {

}

class Analysis extends React.Component<AnalysisProps> {

    constructor(props) {
        super(props);

        this.state = {
            stats: false,
            createChart: false
        }
    }

    handleCreateChart = () => {
        this.setState({createChart: !this.state.createChart})
    };

    handleToggleStats = () => {
        this.setState({stats: !this.state.stats});
    };

    render() {
        const {project, profile, startAt, endAt} = this.props;

        return <Flex flexDirection="column" flexShrink={0}>
            <StyledHeader justifyContent="space-between" alignItems="center">
                <H3 className="text-uppercase m-0">Štatistiky</H3>
                <Flex>
                    <SvgButton id="add-chart-button" name="plus.png" width={28} height={28} onClick={this.handleCreateChart} className="m-2"/>
                    <Tooltip target="add-chart-button" placement="top" color="cloudy-blue">Pridať graf</Tooltip>
                    {!this.state.stats && <SvgButton id="show-chart-button" name="sipka dole.png" width={28} height={28} onClick={this.handleToggleStats} className="m-2"/>}
                    {!this.state.stats && <Tooltip target="show-chart-button" placement="top" color="cloudy-blue">Zobraziť štatistiky</Tooltip>}
                    {this.state.stats && <FlippedSvgButton id="show-chart-button" name="sipka dole.png" width={28} height={28} onClick={this.handleToggleStats} className="m-2"/>}
                    {this.state.stats && <Tooltip target="show-chart-button" placement="top" color="cloudy-blue">Skryť štatistiky</Tooltip>}
                </Flex>
            </StyledHeader>
            {this.state.stats && <Charts project={project} startAt={startAt} endAt={endAt}/>}

            {this.state.createChart && <Modal isOpen={this.state.createChart} toggle={this.handleCreateChart}>
                <ModalHeader toggle={this.handleCreateChart}>Pridať nový graf</ModalHeader>
                <ModalBody>
                    <CreateChart project={project}/>
                </ModalBody>
            </Modal>}
        </Flex>
    }

}

export default connect(
    (state, props) => ({
        profile: state.profile,
        startAt: formValueSelector('search')(state, 'filter.date.from'),
        endAt: formValueSelector('search')(state, 'filter.date.to')
    })
)(Analysis);
