import styled from 'styled-components';

const Container = styled.div`
    position: relative;
    // background: rgba(0, 0, 0, .1);
    margin: 0 auto;
    padding: 0;
    overflow: hidden;
    outline: none;
    
    @keyframes prevent {
        0% { pointer-events: none; }
        100% { pointer-events: auto; }
    }
`;

const Wrapper = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    margin: 0;
`;

const Preloader = styled.div`
    display: hidden;
`;

const Stage = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 0;
    transform-style: preserve-3d;
    perspective: 500px;
`;

const Figure = styled.figure`
    display: block;
    position: relative;
    margin: 0;
    padding: 0;
    flex: 0 0 auto;
    cursor: pointer;
    transition: transform 600ms ease;
    backface-visibility: hidden;
    z-index: 9;
    align-self: center;
    // box-shadow: 0 50px 70px rgba(0,0,0,0.2);
    // TODO: box-reflect crashing the app. Resolve issue
    // -webkit-box-reflect: below 1px; 
    // -webkit-linear-gradient(bottom, rgba(black, .6) , rgba(black, .1) 20%, rgba(black, 0) 30%, rgba(black, 0));
`;

const Cover = styled.div`
    display: block;
    width: 100%;
    box-shadow:2px 2px 5px rgba(0,0,0,.5);
`;

const Text = styled.div`
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    text-align: center;
    font-size: .9em;
    color: white;
    padding: 3px 5px;
    overflow: hidden;
    background: rgba(0, 0, 0, .6);
    line-height: 1.1rem;
    text-overflow: clip;
    height: calc(2.2rem + 3px);
    line-clamp: 2;
`;

const Actions = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    background: rgba(0, 0, 0, .3);
`;

const ArrowWrapper = styled.div`
    display: flex;
    align-items: center;
`;

const size = '50px';
const width = '2px';

const Arrow = styled.div`
    // z-index: 999;
    // opacity: 1 !important;
    //
    // cursor: pointer;
    // display: block;
    // width: calc(${size} / 1.414);
    // height: calc(${size} / 1.414 * 2);
    // position: relative;
    //
    // &.left {
    //     left: ${size};
    // }
    // &.right {
    //     right: ${size};
    // }
    //
    // & span, &:before, &:after {
    //     background: #fff;
    //     box-shadow:2px 2px 8px rgba(0,0,0,.5);
    //     content: '';
    //     display: block;
    //     width: ${size};
    //     height: ${width};
    //     position: absolute;
    //     top: calc(50% - (${width} / 2));
    // }
    //
    // &:before {
    //     transform: rotate(-45deg);
    // }
    // &:after {
    //     transform: rotate(45deg);
    // }
    //
    // & span {
    //     width: 0;
    // }
    // &:hover span {
    //     width: calc(${size} * 1.414);
    // }

    // &.left {
    //     & span, &:before, &:after {
    //         margin: 0 calc(${size} / 4);
    //         transform-origin: left 50%;
    //     }
    //    
    //     &:before, &:after {
    //         transition: left 0.3s 0.05s;
    //     }
    //    
    //     & span {
    //         transition: width 0.3s, left 0.3s 0.05s;
    //     }
    //    
    //     &:hover {
    //         & span, &:before, &:after {
    //             left: calc(${size} / 1.414 * -1);
    //         }
    //     }
    // }    
    //
    // &.right {
    //     & span, &:before, &:after {
    //         margin: 0 calc(${size} / 4);
    //         transform-origin: right 50%;
    //     }
    //    
    //     &:before, &:after {
    //         transition: right 0.3s 0.05s;
    //     }
    //    
    //     & span {
    //         transition: width 0.3s, right 0.3s 0.05s;
    //     }
    //    
    //     &:hover {
    //         & span, &:before, &:after {
    //             right: calc(${size} / 1.414 * -1);
    //         }
    //     }
    // }
`;

export {
    Container,
    Wrapper,
    Preloader,
    Stage,
    Text,
    Cover,
    Figure,
    ArrowWrapper,
    Arrow,
    Actions
}
