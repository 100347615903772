//@flow

import * as React from 'react';
import {connect} from 'react-redux';
import gql from "graphql-tag";
import {change, submit} from "redux-form";
import moment from 'moment';
import {H2} from "@bootstrap-styled/v4";
import styled from "styled-components";

import {Flex, Scrollable} from "../../components";
import {PageTitle, HeaderInfo, PageWrapper} from "../../components-v3";
import {graphql} from "../../actions";
import SearchButton from "./SearchButton";

const StyledTitle = styled(H2)`
    margin-bottom: 0;
    overflow: hidden;
    font-weight: ${props => props.theme['$font-weight-bold']};
    :first-letter {
        text-transform: uppercase;
    }
`;

type HistoryItemProps = {

}

class HistoryItem extends React.PureComponent<HistoryItemProps> {

    render() {
        const {item, sourcesCount} = this.props;

        return <Flex className="py-2">
            <Flex flex={true} flexDirection="column">
                <StyledTitle className="cursor-pointer">{item.query}</StyledTitle>
                <Flex>
                    <HeaderInfo name="ikonka kniha.svg" label="Počet zdrojov">{item.sources.length || sourcesCount} / {sourcesCount}</HeaderInfo>
                    <HeaderInfo name="ikonka hodinky.svg" label="Dátumový rozsah">od {moment(item.startAt).format('DD. MM. YYYY')} do {moment(item.endAt).format('DD. MM. YYYY')}</HeaderInfo>
                    <HeaderInfo name="ikonka terc.svg" label="Počet výsledkov">{item.results}</HeaderInfo>
                </Flex>
            </Flex>
            <Flex alignItems="center">
                <SearchButton id={item.id}/>
            </Flex>
        </Flex>
    }
}

HistoryItem = connect(
    state => {
        return {
            sourcesCount: state.sources.items.length
        }
    }
)(HistoryItem);

type HistoryGroupProps = {

}

class HistoryGroup extends React.PureComponent<HistoryGroupProps> {

    render() {
        const {label, items, ...rest} = this.props;

        return <>
            {/*<div className="bg-pale-gray font-weight-semi-bold text-mid-gray px-4 py-3">{moment(items[0].createdAt).fromNow()}, <Moment format="DD MMM YYYY">{items[0].createdAt}</Moment></div>*/}
            {items.map((item, index) => <HistoryItem key={index} item={item} {...rest}/>)}
        </>
    }
}

type HistoryProps = {

}

class History extends React.Component<HistoryProps> {

    componentDidMount(): * {
        const {getHistory} = this.props;

        getHistory();
    }

    render() {
        const {queries} = this.props;

            return <Scrollable flex={true} flexDirection="column">
            <PageWrapper flex="0 0 auto" className="justify-content-between align-items-center border-bottom py-2">
                <PageTitle>História</PageTitle>
            </PageWrapper>
            <PageWrapper flex="1 0 auto" flexDirection="column">
                {Object.keys(queries).map((date, index) => <HistoryGroup key={index} label={date} items={queries[date]} />)}
            </PageWrapper>
        </Scrollable>
    }
}

export default connect(
    (state, props) => {
        const history = [];
        state.history.items.forEach(item => {
            const group = moment(item.createdAt).format('YYYY-MM-DD');
            history[group] = [
                ...history?.[group] || [],
                item
            ]
        });
        return {
            queries: history
        }
    },
    (dispatch, prosp) => {
        return {
            getHistory: () => dispatch(graphql('history', 'history', gql`query History {
                history {
                    id
                    query
                    sources {
                        id
                        name
                    }
                    startAt
                    endAt
                    createdAt
                    results
                    saved {
                        id
                    }
                }
            }`)),
            setField: (field, value) => dispatch(change('search', field, value)),
            submitForm: () => dispatch(submit('search'))
        }
    }
)(History);
