// @flow

import * as React from 'react';
import {connect} from 'react-redux';

import {CloudChart} from "../ChartTypes";
import {keywordsCloud} from "../../../queries";

class KeywordsCloud extends React.PureComponent {

    componentDidMount() {
        const {getData} = this.props;

        getData();
    }

    render() {
        const {chart, sources, data} = this.props;

        if (data.isFetching) {
            return null;
        }

        return <CloudChart chart={chart}
                           title={chart.name}
                           series={data.items.map(item => ({name: item.key, weight: item.doc_count}))}
        />
    }
}

KeywordsCloud = connect(
    (state, props) => ({
        data: state.keywordsCloud
    }),
    (dispatch, props) => ({
        getData: () => dispatch(keywordsCloud({
            chart: props.chart.id,
            startAt: props.startAt,
            endAt: props.endAt
        }))
    })
)(KeywordsCloud);

export default KeywordsCloud;