//@flow

import React from 'react';
import styled from 'styled-components';
import {Button, Input, Modal, ModalBody, ModalFooter, ModalHeader} from "@bootstrap-styled/v4";

import {SvgButton} from "../../../../components-v3";
import {Flex, Tooltip} from "../../../../components";

const QueryField = styled(Flex).attrs({className: 'm-1'})`
    position: relative;
`;

const HelpButton = styled(SvgButton)`
    position: absolute;
    top: 10px;
    right: 10px;
`;

const StyledInput = styled(Input).attrs({className: 'flex-grow-1 px-3 py-2'})`
    font-family: "${props => props.theme['$font-family-base']}";
    font-size: ${props => props.theme['$font-size-base']};
    color: ${props => props.theme['$black']};
    border: 1px solid ${props => props.theme['$silver']};
    line-height: ${props => props.theme['$input-line-height']};
`;

class SearchHelp extends React.PureComponent {

    render() {
        const {onClose} = this.props;

        return <Modal isOpen={true} toggle={onClose} size="lg">
            <ModalHeader toggle={onClose}>Pomoc pri tvorbe dotazu</ModalHeader>
            <ModalBody>
                <h2>Jednoslovné dotazy</h2>

                <pre>slovo</pre>
                <p>Výsledkom je výpis článkov, ktoré obsahujú vyhľadávaný termín v pôvodnej aj vyskloňovanej alebo vyčasovanej podobe. Neprihliada sa ani na veľké či malé písmená.</p>

                <pre>"fráza" – presné vyhľadávanie</pre>
                <p>Výsledkom sú články, ktoré obsahujú presnú podobu slova, alebo slovného spojenia aj so zohľadnením veľkých a malých písmen</p>
                <p><strong>Upozornenie:</strong> <em>Operátorom nie sú typografické úvodzovky – horné a dolné.</em></p>

                <pre>slov*</pre>
                <p>operátor * nahrádza ľubovoľný počet znakov nasledujúcich za koreňom slova.</p>
                <p>Výsledkom sú články ktoré obsahujú pojmy začínajúce znakmi slov – čiže napr. slovo, Slovák, slovenčina, slovník atď. Využitie je vhodné pri hľadaní vlastných mien a priezvisk.</p>

                <pre>sl?v?</pre>

                <p>operátor ? nahrádza práve jeden znak kdekoľvek v slove, v jednom slove môže byť použitý viackrát. Využitie je vhodné najmä pri hľadaní vlastných mien a priezvisk, kde si nie sme istí presnou podobou mena.</p>
                <p>Výsledkom sú články obsahujúce napr. slovo, slová, sláva, sliva</p>
                <p><strong>Upozornenie:</strong> <em>Použitie presného vyhľadávania s pomocou úvodzoviek, sa logicky vylučuje s použitím operátorov * a ?.</em></p>

                <h2>Vyhľadávanie kombinácií viacerých slov</h2>

                <h2>Operátory OR, AND, NOT</h2>

                <h3>Operátor OR (|) (ctrl+alt+w)</h3>
                <p>je predvolený operátor systému a teda nie je potrebné ho zadávať</p>

                <pre>slovo1 slovo2</pre>
                <p>Výsledkom sú články, ktoré obsahujú slovo1 alebo slovo 2 (v základnej, vyskloňovanej alebo vyčasovanej podobe)</p>

                <h3>operátor AND (+)</h3>

                <pre>+slovo1 +slovo2 (identické so zadaním slovo1 AND slovo2)</pre>
                <p>Výsledkom sú články, ktoré obsahujú slovo1, aj slovo2</p>

                <pre>slovo1 +slovo2 (identické so zadaním slovo2 OR slovo1)</pre>
                <p>Výsledkom sú články, ktoré obsahujú slovo2 a môžu obsahovať slovo1, ktoré bude tiež podfarbené, články s oboma slovami budú mať vyššiu relevanciu</p>

                <h3>operátor NOT (-)</h3>
                <pre>+slovo1 -slovo2 (identické so zadaním slovo1 NOT slovo2)</pre>
                <p>Výsledkom sú články, ktoré obsahujú slovo2 a neobsahujú slovo1</p>

                <h3>Ďalšie operátory</h3>
                <pre>"slovo1 slovo2" – presné vyhľadávanie</pre>
                <p>výsledkom sú články, ktoré obsahujú presnú podobu slovného spojenia aj so zohľadnením veľkých a malých písmen</p>

                <pre>"slovo1 slovo2"~3</pre>
                <p>Operátor ~ určuje v prípade frázy maximálnu prípustnú vzdialenosť medzi slovami</p>
                <p>Výsledkom sú články, ktoré obsahujú slovo1 a slovo2, pričom medzi nimi nie sú viac ako 3 iné slová</p>

                <pre>slovo1 NOT (slovo2 slovo3)</pre>
                <p>() vytvorí skupinu slov, na ktoré sa vzťahuje operácia</p>
                <p>Výsledkom sú články, ktoré obsahujú slovo1 ale neobsahujú ani slovo2, ani slovo3</p>

                <h2>Vyhľadávanie v konkrétnom databázovom poli</h2>
                <h3>Vyhľadávanie podľa autora</h3>

                <pre>author: Novák</pre>
                <p>Výsledkom sú články všetkých autorov s priezviskom Novák</p>
                <p>Pri vyhľadávaní v poli author je možné použiť aj operátor *</p>

                <pre>author: "Peter Novák"</pre>
                <p>Výsledkom sú len články autora Petra Nováka</p>

                <h3>Vyhľadávanie podľa servisu</h3>
                <pre>service: šport</pre>
                <p>Výsledkom sú správy, ktoré majú ako servis definovaný šport</p>
                <p>Pri vyhľadávaní v poli service je možné použiť aj operátor *</p>
                <p>V prípade viacslovného názvu servisu je potrebné použiť úvodzovky</p>

                <pre>service: "ľadový hokej"</pre>
                <p><strong>Upozornenie:</strong> <em>Vyhľadávanie v databázovom poli je viazané len na prvý výraz za definíciou poľa, preto musí byť viacslovné spojenie v úvodzovkách. Ďalšie slovo bez úvodzoviek je považované za nový vyhľadávaný výraz a použije sa nad všetkými poliami s východiskovým operátorom OR.</em></p>

                <p>V prípade vyhľadávania viacerých servisov, alebo alternatívnych názvov, je potrebné vyhľadávať ako skupinu slov a teda použiť zátvorky</p>

                <pre>service: (reklama inzercia)</pre>

                <h2>Kombinovanie operátorov do komplexných reťazcov</h2>
                <h3>Jednotlivé operátory je možné pri tvorbe dotazu kombinovať:</h3>

                <pre>B?d?r NOT (Kaliňák, Fico)</pre>
                <pre>service: prominenti +Gott</pre>
            </ModalBody>
            <ModalFooter>
                <Button color="secondary" onClick={onClose}>Zatvoriť</Button>
            </ModalFooter>
        </Modal>
    }
}


export default class extends React.PureComponent {

    constructor(props) {
        super(props);

        this.state = {
            isOpen: false
        }
    }

    handleToggle = () => {
        this.setState({isOpen: !this.state.isOpen});
    };

    handleChange = event => {
        const {input, onSubmit} = this.props;

        if (-1 !== event.target.value.indexOf("\n")) {
            onSubmit();
        }
        input.onChange(event.target.value.replace(/\n/g, ''));
    };

    render() {
        const {input, meta, ...rest} = this.props;

        return <QueryField flex={1}>
            <StyledInput type="textarea" rows="1" placeholder="Zadajte hľadaný výraz ..." {...input} {...rest} onChange={this.handleChange}/>
            <HelpButton id="search-help-button" name="ikonka napoveda mala.svg" width={20} height={20} onClick={this.handleToggle}/>
            <Tooltip target="search-help-button" placement="top" color="cloudy-blue">Nápoveda</Tooltip>
            {this.state.isOpen && <SearchHelp onClose={this.handleToggle}/>}
        </QueryField>
    }
}
