import alpha from "color-alpha";

const cloudyBlue= '#007bff';
const gray= '#a2a2a2';
const white= '#ffffff';
const black= '#000000';
const purple= '#181a22';
const lightPurple= '#262a3a';
const brown= '#a88979';
const teal= '#57d1d8';
const seagull= '#79ceea'; // http=//chir.ag/projects/name-that-color/'#007BFF
const seagull25 = alpha(seagull, .25);
const lightishBlue= '#5468ff';
const lightishBlue25= 'rgba(84, 104, 255, .25)';
const pink= '#eb83cc';
const violet= '#c038ae';
const red= '#f61f2f';
const paleGray= '#f0f0f4';
const lightPink= '#ea7a7a';
const yellow= '#caa824';
const gallery = '#ededed';
const gallery09 = alpha(gallery, .09);
const gallery25 = alpha(gallery, .25);
const gallery54 = alpha(gallery, .54);
const apricot = '#ea7a7a';
const stormyGray = '#6d7284';
const midGray = '#5c5c66';

const primary= white;
const secondary= black;

export {
    cloudyBlue,
    gray,
    gallery,
    gallery09,
    gallery25,
    gallery54,
    white,
    black,
    purple,
    lightPurple,
    brown,
    teal,
    seagull,
    seagull25,
    lightishBlue,
    lightishBlue25,
    pink,
    violet,
    red,
    paleGray,
    lightPink,
    yellow,
    apricot,
    stormyGray,
    midGray,
    primary,
    secondary
}