import * as React from 'react';
import {withRouter} from "react-router-dom";

import MenuItem from "../../components-v3/MenuItem";

class History extends React.PureComponent {

    render() {
        const {location} = this.props;

        return <MenuItem to="/history"
                         active={-1 !== location.pathname.indexOf('history')}
        >História vyhľadávania</MenuItem>
    }
}

export default withRouter(History);