import * as React from 'react';
import styled from 'styled-components';

import {SvgButton} from '../../components-v3';
import {Flex, Tooltip} from '../../components';
import SelectedArticlesCounter from "./SelectedArticlesCounter";

const StyledSelectedArticlesCounter = styled(SelectedArticlesCounter)`
    position: relative;
    top: -10px;
    left: -10px;
`;

const StyledExportButton = styled(Flex).attrs({className: 'align-items-center'})`
    position: relative;
    cursor: pointer;
`;

export default class extends React.PureComponent {

    render() {
        const {onClick} = this.props;

        return <StyledExportButton onClick={onClick}>
            <SvgButton id="export-button" name="export.png" width={27} height={28}/>
            <Tooltip target="export-button" placement="top" color="cloudy-blue">Exportovať výber</Tooltip>
            <StyledSelectedArticlesCounter/>
        </StyledExportButton>
    }
}
