//@flow

import * as React from 'react';
import {Link} from "react-router-dom";

import {SvgButton} from "../../components-v3";
import {Tooltip} from "../../components";

type SearchButtonProps = {
    id: number
}

export default ({id}: SearchButtonProps) => <Link to={`/history/${id}`}>
    <SvgButton id={`search-button-${id}`} className="m-2" name="ikonka lupa velka.svg" width={27} height={27}/>
    <Tooltip target={`search-button-${id}`} placement="bottom" color="cloudy-blue">Vyhľadať</Tooltip>
</Link>
