// @flow

import * as React from 'react';
import classnames from 'classnames';
import styled from 'styled-components';
import Flex from "./Flex";

const Checkbox = styled(Flex)`
    position: relative;
    align-items: center;
`;

const Input = styled.div`
    width: 1rem;
    height: 1rem;
    display: inline-block;
    border: 1px solid ${props => props.theme['$silver']};
    background-color: ${props => props.theme['$white']};
    &.checked { 
        background-color: ${props => props.theme['$yellow-orange']};
        border: 1px solid ${props => props.theme['$yellow-orange']};
    }
    &:hover { 
        border: 1px solid ${props => props.theme['$black']};
    }
`;

const Label = styled.div`
    line-height: 20px;
    padding-left: ${props => props.theme['$spacers'][2]};
    font-weight: ${props => props.theme['$font-weight-medium']};
`;

type CheckboxProps = {
    label: string,
    checked: boolean,
    onChange: () => void
}

export default class extends React.PureComponent<CheckboxProps> {

    render() {
        const { label, checked, onChange } = this.props;

        return <Checkbox onClick={onChange}>
            <Input className={classnames({ 'checked': checked })}/>
            {label && <Label>{label}</Label> }
        </Checkbox>
    }
}