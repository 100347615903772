//@flow

import * as React from 'react';
import { connect } from 'react-redux';
import { change } from 'redux-form';
import {Button} from '@bootstrap-styled/v4';
import styled from 'styled-components';

import {Flex} from "../components";
import Sources from './Sources';

const StyledFooter = styled(Flex)`
    justify-content: flex-end;
    padding: ${props => props.theme['$spacers'][3]};
`;

const StyledButton = styled(Button)`
    margin: auto ${props => props.theme['$spacers'][2]};
`;

class SourcesFilter extends React.PureComponent {

    handleClose = () => {
        const { onClose } = this.props;

        onClose();
    };

    handleUnselectAll = () => {
        const { unselectAll } = this.props;

        unselectAll();
    };

    get footer(): React.Node {
        return <StyledFooter flex={1}>
            <StyledButton className="mx-2" color="secondary" onClick={this.handleUnselectAll}>Vymazať</StyledButton>
            <StyledButton className="mx-2" color="primary" onClick={this.handleClose}>Potvrdiť</StyledButton>
        </StyledFooter>
    }

    render() {
        const {input} = this.props;

        return <Flex flexDirection="column">
            <Sources input={input}/>
            {this.footer}
        </Flex>
    }
}

export default connect(
    (state, props) => {
        return {}
    },
    (dispatch, props) => {
        return {
            unselectAll: sources => dispatch(change('search', 'filter.sources', sources)),
        }
    },
    (stateProps, dispatchProps, ownProps) => {
        return Object.assign({}, ownProps, stateProps, dispatchProps, {
            unselectAll: () => dispatchProps.unselectAll([]),
        });
    }
)(SourcesFilter);