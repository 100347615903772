// @flow

import * as React from 'react';
import Loader from './Loader';

// const LoaderWrapper = styled.div`
//     position: absolute;
//     top: 0;
//     bottom: 0;
//     left: 0;
//     right: 0;
//     background: white;
//     opacity: .9;
//     padding-top: 200px;
//     z-index': 1000;
// `;

export default class extends React.PureComponent {

    render() {

        return <div className="loader-wrapper" style={{
            'position': 'absolute',
            'top': 0,
            'bottom': 0,
            'left': 0,
            'right': 0,
            'background': 'white',
            'opacity': '.7',
            'paddingTop': '200px',
            'zIndex' : 1000
        }}>
            <Loader size="lg"/>
        </div>;
    }
}