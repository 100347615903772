import {graphql} from "../actions";
import gql from "graphql-tag";

export default variables => graphql('mentionsInTime', 'mentionsInTime', gql`query MentionsInTime(
    $chart: String!
    $startAt: String!
    $endAt: String!
) {
    mentionsInTime(chart: $chart, startAt: $startAt, endAt: $endAt) {
        key
        doc_count
    }
}`, 'query', variables)