import * as React from 'react';

import {SvgButton, MenuItem} from '../../components-v3';
import {Tooltip} from "../../components";

export default class extends React.PureComponent {

    render() {
        return <MenuItem to="/logout" >
            <SvgButton id="logout-button" name="ikonka logout.png" width={26} height={27}/>
            <Tooltip target="logout-button" placement="top" color="cloudy-blue">Odhlásiť</Tooltip>
        </MenuItem>
    }
}
