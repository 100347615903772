//@flow

import * as React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { Flex } from "../../components";
import Source from "./Source";
import SelectIcons from "./SelectIcons";
import sourceFilter from "./sourceFilter";

type FilteredSourceProps = {
    filter: string,
    value: [],
    onChange: () => void
}

class FilteredSource extends React.PureComponent<FilteredSourceProps> {

    render() {
        const { filter, value, onChange, ...props } = this.props;

        if (sourceFilter(this.props.source, filter)) {
            return null;
        }

        return <Source {...props} value={value} onChange={onChange} />
    }
}

const StyledSourceGroupHeader = styled(Flex)`
    font-size: .9rem;
    justify-content: space-between;
    border-bottom: 1px solid silver;
    padding: 8px 0;
    align-items: center
    
    &:hover {
        background-color: #eee;
    }
`;

const StyledSourceGroupHeaderName = styled(Flex)`
    align-items: center;
`;

const StyledSourceGroup = styled(Flex)`
    flex-wrap: wrap;
`;

const StyledSourceGroupLabel = styled.span.attrs({className: 'd-inline-block font-weight-semi-bold'})`
    color: ${props => props.active ? props.theme['$yellow-orange'] : props.theme['$black']};
    cursor: pointer;
`;

const StyledSourceGroupCount = styled.span.attrs({className: "font-weight-medium"})`
    padding: 0 ${props => props.theme['$spacers'][2]};
`;

type SourceGroupProps = {
    selectAll: () => void,
    unselectAll: () => void,
    invertSelection: () => void
}

type SourceGroupState = {
    opened: boolean
}

class SourceGroup extends React.Component<SourceGroupProps, SourceGroupState> {

    constructor(props) {
        super(props);

        this.state = {
            opened: false
        }
    }

    handleSelectAll = () => {
        const { selectAll } = this.props;

        selectAll();
    };

    handleUnselectAll = () => {
        const { unselectAll } = this.props;

        unselectAll();
    };

    handleInvertSelection = () => {
        const { invertSelection } = this.props;

        invertSelection();
    };

    handleToggle = () => {
        this.setState({opened: !this.state.opened});
    };

    render() {
        const { sourceGroup, input: {value, onChange}, label, groupSelected, filter } = this.props;

        return <div className="mb-2">
            <StyledSourceGroupHeader>
                <StyledSourceGroupHeaderName>
                    <StyledSourceGroupLabel active={this.state.opened} onClick={this.handleToggle}>{label}</StyledSourceGroupLabel>
                    <StyledSourceGroupCount>{groupSelected.length}/{sourceGroup.length}</StyledSourceGroupCount>
                    {this.state.opened ? <img className="cursor-pointer" src={require('../../assets/img/dropdown.svg')} alt="" onClick={this.handleToggle}/>
                        : <img className="cursor-pointer" src={require('../../assets/img/dropdown.svg')} alt="" style={{transform: 'rotate(180deg)'}} onClick={this.handleToggle}/>}
                </StyledSourceGroupHeaderName>
                <Flex>
                    <SelectIcons onSelect={this.handleSelectAll} onUnselect={this.handleUnselectAll} onInvert={this.handleInvertSelection}/>
                </Flex>
            </StyledSourceGroupHeader>
            {(this.state.opened || filter) && <StyledSourceGroup>
                {sourceGroup.map(source => <FilteredSource filter={filter} key={source.id} value={value} onChange={onChange} source={source}/>)}
            </StyledSourceGroup>}
        </div>
    }
}

export default connect(
    (state, props) => {
        const ids = props.sourceGroup.map(source => source.id);
        return {
            selected: props.selected,
            groupSelected: props.selected ? ids.filter(id => -1 !== props.selected.indexOf(id)) : []
        }
    },
    (dispatch, props) => ({}),
    (stateProps, dispatchProps, ownProps) => {
        const ids = ownProps.sourceGroup.filter(source => !sourceFilter(source, ownProps.filter)).map(source => source.id);
        return Object.assign({}, ownProps, stateProps, dispatchProps, {
            selectAll: () => {
                const selected = [
                    ...stateProps.selected,
                    ...ids
                ];
                ownProps.onChange(selected.filter((item, index) => index === selected.indexOf(item)))
            },
            unselectAll: () => ownProps.onChange(stateProps.selected.filter(id => -1 === ids.indexOf(id))),
            invertSelection: () => ownProps.onChange([
                ...stateProps.selected.filter(id => -1 === ids.indexOf(id)),
                ...ids.filter(id => -1 === stateProps.selected.indexOf(id))
            ])
        });
    }
)(SourceGroup);
