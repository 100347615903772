import * as React from 'react';
import {withRouter} from "react-router-dom";

import MenuItem from "../../components-v3/MenuItem";

class Search extends React.PureComponent {

    render() {
        const {location} = this.props;

        return <MenuItem to="/new"
                         active={-1 !== location.pathname.indexOf('new') || -1 !== location.pathname.indexOf('project/')}
        >Vyhľadávanie</MenuItem>
    }
}

export default withRouter(Search);
