import * as React from 'react';
import moment, { type Moment } from 'moment';
import { connect } from 'react-redux';
import styled from 'styled-components';
import MediaQuery from 'react-responsive';

import FastNavigation from './FastNavigation';
import {Flex} from './components';
import DatePicker from './DatePicker';
import {paleGray} from '../../variables';
import {Input, Label, Hr, FormGroup} from "@bootstrap-styled/v4";

type DatePickerProps = {
    active: ?string,
    from: Moment,
    to: Moment,
    range: ?boolean,
    onChange: (value: {from: string, to: string}) => void
};

type DatePickerState = {
    active: Moment,
    from: Moment,
    to: ?Moment,
    hover: Moment,
    mode: ['date', 'month', 'year']
}

const Divider = styled.div`
    border-right: 1px solid ${paleGray}
    margin-left: ${props => props.theme['$spacers'][3]};
    margin-right: ${props => props.theme['$spacers'][3]};
`;

const StyledRangeDatePicker = styled(Flex)`
    padding: ${props => props.theme['$spacers'][2]} 0;
    flex-direction: column;
    
    @media only screen and (min-width: 768px) {
        flex-direction: row;
    }
`;

const StyledInput = styled(Input)`
    margin: ${props => props.theme['$spacers'][1]} 0;
    border: 1px solid ${props => props.theme['$silver']};
    width: 100%;
    background: white;
    padding: ${props => props.theme['$spacers'][1]} ${props => props.theme['$spacers'][2]}
`;

const StyledHr = styled(Hr)`
    border: 1px solid ${props => props.theme['$silver']};
    width: 100%;
`;

class RangeDatePicker extends React.Component<DatePickerProps, DatePickerState> {

    static defaultProps = {
        range: false,
        active: moment()
    };

    constructor(props) {
        super(props);

        this.state = {
            active: props.active,
            type: props.type,
            from: props.from,
            to: props.to,
            hover: null,
            mode: 'date'
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {from, to} = this.props;

        if (prevProps.from !== from) {
            this.setState({from: from});
        }
        if (prevProps.to !== to) {
            this.setState({to: to});
        }
    }

    componentWillUnmount() {
        if (!this.state.to) {
            this.handleClick(this.state.from);
        }
    }

    handleTypeChange = type => {
        const {onChange} = this.props;

        this.setState({type: type});
        onChange({
            type: type,
            from: this.state.from,
            to: this.state.to
        });
    };

    handleFromChange = date => {
        const {onChange} = this.props;

        this.setState({from: date});
        onChange({
            type: this.state.type,
            from: date,
            to: this.state.to
        });
    };

    handleToChange = date => {
        const {onChange} = this.props;

        this.setState({to: date});
        onChange({
            type: this.state.type,
            from: this.state.from,
            to: date
        });
    };

    handleFastChange = (from, to) => {
        const { onChange } = this.props;

        const value = {
            type: this.state.type,
            from: from,
            to: to
        };

        this.setState(value);
        onChange(value);

        this.forceUpdate();
    };

    render() {
        return <>
            <StyledRangeDatePicker>
                <MediaQuery minWidth={768}>
                    <DatePicker label="Dátum od" date={this.state.from} from={this.state.from} to={this.state.to} onChange={this.handleFromChange}/>
                    <Divider/>
                    <DatePicker label="Dátum do" date={this.state.to} from={this.state.from} to={this.state.to} onChange={this.handleToChange}/>
                    <Divider/>
                </MediaQuery>
                <MediaQuery maxWidth={767}>
                    <Label>Dátum od</Label>
                    <StyledInput type="date" onChange={props => this.handleFromChange(moment(props.target.value))} value={this.state.from.format('YYYY-MM-DD')}/>
                    <Label>Dátum do</Label>
                    <StyledInput type="date" onChange={props => this.handleToChange(moment(props.target.value))} value={this.state.to.format('YYYY-MM-DD')}/>
                    <StyledHr />
                </MediaQuery>
                {this.props.range && <FastNavigation onChange={this.handleFastChange}/>}
            </StyledRangeDatePicker>
            {(-1 !== ['windisch@winit.sk', 'tytykalo@sita.sk', 'samel@sita.sk'].indexOf(this.props.profile.data.username)) && (
                <Flex>
                    <FormGroup check={true}>
                        <Input id="type-date" type="radio" name="type" value="date" checked={'date' === this.state.type} onChange={() => this.handleTypeChange('date')} />
                        <Label for="type-date" className={"label-color"}>Dátum článku</Label>
                    </FormGroup>
                    <FormGroup check={true}>
                        <Input id="type-imported-at" type="radio" name="type" value="imported_at" checked={'imported_at' === this.state.type} onChange={() => this.handleTypeChange('imported_at')} />
                        <Label for="type-imported-at" className={"label-color"}>Dátum importovania</Label>
                    </FormGroup>
                </Flex>
            )}
        </>
    }
}

export default connect(
    (state, props) => {
        return {
            client: state.client,
            profile: state.profile
        }
    }
)(RangeDatePicker);