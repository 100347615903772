//@flow

import * as React from 'react';
import {connect} from 'react-redux';

import ProjectForm from './ProjectForm';
import {updateProject} from "../../queries";

type UpdateProjectProps = {
    id: string,
    name: string,
    query: string,
    sources: [],
    startAt: string,
    endAt: string,
    onReset: () => void,
    onUpdate: () => void
}

const UpdateProject:React.AbstractComponent<UpdateProjectProps> = connect(
    (state, props: UpdateProjectProps) => {
        return {
            form: `project_${props.id}`,
            initialValues: {
                name: props.name,
                query: props.query,
                sources: props.sources.map(source => source.id),
                date: {
                    startAt: props.startAt,
                    endAt: props.endAt
                }
            }
        }
    },
    (dispatch, props: UpdateProjectProps) => {
        return {
            save: values => dispatch(updateProject({
                id: props.id,
                ...values
            }))
                .then(props.onUpdate)

        }
    }
)(ProjectForm);

export default UpdateProject;