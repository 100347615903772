import React, { Component } from 'react';
import moment, {type Moment} from 'moment';
import {Button, Modal, ModalBody, ModalHeader, Tooltip} from "@bootstrap-styled/v4";
import styled from 'styled-components';
import MediaQuery from 'react-responsive';

import DatePicker from './DatePicker';
import {Icon} from "../components";
import {Flex} from "./DatePicker/components";
import {Input} from '../components-v3';

const StyledFooter = styled(Flex)`
    justify-content: flex-end;
`;

const StyledTooltip = styled(Tooltip)`
    .tooltip-inner {
        padding: ${props => props.theme['$spacers'][3]};
        color: ${props => props.theme['$black']};
        background-color: ${props => props.theme['$white']};
        border: 1px solid ${props => props.theme['$black']};
        border-radius: 0;
        max-width: none;
    }
`;

export default class DatePickerField extends Component {

    constructor(props) {
        super(props);

        this.state = {
            open: false
        }
    }

    handleToggle = () => {
        this.setState({
            open: !this.state.open
        });
    };

    handleChange = value => {
        const { input: { onChange }} = this.props;

        onChange({
            type: value.type,
            from: value.from.format('YYYY-MM-DD'),
            to: value.to ? value.to.format('YYYY-MM-DD') : null
        })
    };

    get from(): ?Moment {
        const {input: { value }} = this.props;

        return value.from && moment(value.from);
    }

    get to(): ?Moment {
        const {input: { value }} = this.props;

        return value.to && moment(value.to);
    }

    get type() {
        const {input: { value }} = this.props;

        return value.type;
    }

    get footer(): React.Node {
        return <StyledFooter flex={1}>
            <Button color="primary" onClick={this.handleToggle}>Potvrdiť</Button>
        </StyledFooter>
    }

    get changed() {
        const { input: { value }} = this.props;

        return !moment(value.from).isSame(moment().subtract(1, 'd'), 'd') || !moment(value.to).isSame(moment().subtract(1, 'd'), 'd');
    }

    render() {
        const { input: { value }} = this.props;

        return <>
            <Input id="datepicker-button" onClick={this.handleToggle}>
                <Icon type="calendar" size={14}/>
                <span className="ml-2"><strong>Od</strong> {value.from && moment(value.from).format('DD/MM/YYYY')} - <strong>Do</strong> {value.to && moment(value.to).format('DD/MM/YYYY')}</span>
            </Input>
            <MediaQuery minWidth={1200}>
                {this.state.open && <StyledTooltip  autohide={false} isOpen={this.state.open} target="datepicker-button" toggle={this.handleToggle} tether={{
                    offset: '-3px -5px',
                    attachment: 'top right',
                    targetAttachment: 'bottom right',
                    constraints: [

                    ]
                }}>
                    <DatePicker range={true} from={this.from} to={this.to} type={this.type} onChange={this.handleChange}/>
                    <div className="m-2">
                        {this.footer}
                    </div>
                </StyledTooltip>}
            </MediaQuery>
            <MediaQuery maxWidth={1191}>
                {this.state.open && <Modal isOpen={this.state.open} toggle={this.handleToggle}>
                    <ModalBody>
                        <DatePicker range={true} from={this.from} to={this.to} type={this.type} onChange={this.handleChange}/>
                        <div className="m-2">
                            {this.footer}
                        </div>
                    </ModalBody>
                </Modal>}
            </MediaQuery>
        </>
    }
}