// @flow

import * as React from 'react';
import {Button, Form, FormGroup, Label} from "@bootstrap-styled/v4";
import {Field, reduxForm, change, formValueSelector} from "redux-form";
import InputField from "../../fields/InputField";
import SelectField from "../../fields/SelectField";
import {connect} from "react-redux";

class ChartForm extends React.PureComponent {

    handleChangeType = (event, newValue, oldValue) => {
        const {setName, name} = this.props;

        const oldOption = this.options.find(option => option.value === oldValue);
        const newOption = this.options.find(option => option.value === newValue);

        if (('undefined' === typeof name) || (oldOption?.label === name)) {
            setName(newOption.label);
        }
    };

    get options() {
        return [
            {value: 'mentions-in-time', label: 'Zmienky v čase'},
            {value: 'top-source-types', label: 'Top typy zdrojov'},
            {value: 'top-sources', label: 'Top zdroje'},
            {value: 'keywords-cloud', label: 'Výskyt kľúčových slov'},
            {value: 'top-authors', label: 'Top autori'},
        ]
    }

    render() {
        const {handleSubmit} = this.props;

        return <Form>
            <FormGroup>
                <Label>Meno</Label>
                <Field name="name" component={InputField} />
            </FormGroup>
            <FormGroup>
                <Label>Typ</Label>
                <Field name="type" component={SelectField} options={this.options} onChange={this.handleChangeType}/>
            </FormGroup>
            <Button onClick={handleSubmit}>Uložiť</Button>
        </Form>
    }
}

ChartForm = connect(
    (state, props) => ({
        name: formValueSelector('chart')(state, 'name')
    }),
    (dispatch, props) => ({
        setName: name => dispatch(change('chart', 'name', name))
    })
)(ChartForm);

ChartForm = reduxForm({
    form: 'chart',
    enableReinitialize: true,
})(ChartForm);

export default ChartForm;