import {graphql} from "../actions";
import gql from "graphql-tag";

export default variables => graphql('search', 'search', gql`query Search(
    $query: String!,
    $type: DateTypes = date,
    $startAt: String!,
    $endAt: String!,
    $sources: [Int!],
    $orderBy: SearchOrderFields,
    $direction: SearchOrderDirections, 
    $offset: Int = 0,
    $limit: Int = 25
) {
    search(
        query: $query,
        type: $type,
        from: $startAt,
        to: $endAt,
        source: $sources
        orderBy: $orderBy,
        direction: $direction,
        offset: $offset,
        limit: $limit
    ) {
        data {
            id
            title
            service
            author
            url
            content
            date
            page
            source {
                name
                sourceGroup {
                    id
                }
                grp
            }
            clippings {
                id
                url
            }
            keywords
            score
            ave
        }
        meta {
            count
            offset
            limit
        }
    }
}`, 'query', variables)