// @flow

import React, { PureComponent, Node } from 'react';
import PropTypes from 'prop-types';
import Button from './Button';

export default class extends PureComponent {

    static propTypes = {
        checked: PropTypes.bool,
        children: PropTypes.string,
        onChange: PropTypes.func
    };

    render(): Node {
        const { checked, onChange, children } = this.props;

        return <Button color={checked ? 'pale-gray' : 'primary'} onClick={onChange}>
            <strong className="text-uppercase">{children}</strong>
        </Button>
    }
}