// @flow

import {graphql} from "../actions";
import gql from "graphql-tag";

export type RemoveProps = {
    id: string,
};

export default (variables: RemoveProps) => graphql('removeProject', 'removeProject', gql`mutation RemoveProject($id: String!) {
    removeProject(id: $id)
}`, 'mutation', variables)