//@flow

import styled, {css} from 'styled-components';

import { Flex } from './components';
import {apricot, ebonyClay, shark} from './variables'

const otherMonthMixin = css`
    color: gray;
`;

const todayMixin = css`
    color: red;
`;

const hoverMixin = css`
    background-color: ${ebonyClay}; 
    color: white; 
    opacity: .5;
`;

const selectedMixin = css`
    background-color: ${ebonyClay}; 
    color: white; 
    opacity: 1;
`;

const firstMixin = css`
    border-top-left-radius: 6px; 
    border-bottom-left-radius: 6px;
`;

const lastMixin = css`
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
`;

const markMixin = css`
    &:before { 
        content: ""; 
        position: absolute; 
        width: 6px; 
        height: 6px; 
        top: 5px; 
        left: 30px; 
        background-color: ${props => props.selected || props.hover ? 'white' : apricot}; 
        border-radius: 3px;
    }
`;

const Cell = styled(Flex).attrs({flex: true})`
    justify-content: center;
    line-height: 2.2rem; 
    position: relative;
    color: ${shark};
    cursor: pointer;
    width: 33px;
    padding-left: ${props => props.theme['$spacers'][2]};
    padding-right: ${props => props.theme['$spacers'][2]};
`;

export const DayCell = styled(Cell).attrs({
    className: 'font-weight-bold text-capitalize'
})`
    justify-content: center
`;

export const DateCell = styled(Cell)`
    ${props => props.otherMonth && otherMonthMixin}
    ${props => props.today && todayMixin}
    ${props => props.hover && hoverMixin}
    ${props => props.selected && selectedMixin}
    ${props => props.first && firstMixin}
    ${props => props.last && lastMixin}
    ${props => props.mark && markMixin}
`;
