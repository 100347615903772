import {graphql} from "../actions";
import gql from "graphql-tag";

export default variables => graphql('createExport', 'createExport', gql`mutation CreateExport(
    $query: String!,
    $startAt: String!,
    $endAt: String!,
    $type: String!,
    $maxResults: Int!,
    $sources: [Int!],
    $articles: [String!]!
) {
    createExport(
        query: $query,
        startAt: $startAt,
        endAt: $endAt,
        type: $type,
        maxResults: $maxResults,
        sources: $sources,
        articles: $articles
    ) {
        id
        export
    }
}`, 'mutation', variables)