//@flow

import * as React from 'react';
import {A} from '@bootstrap-styled/v4'

import {Flex, Scrollable} from "../../components";
import {PageTitle} from "../../components-v3";
import Projects from './Projects';
import CreateProject from './CreateProject';
import styled from "styled-components";

const StyledNewSearch = styled(A)`
    cursor: pointer;
`;

type ProjectsProps = {
    projects: {
        items: []
    },
    getProjects: () => Promise<any>
}

type ProjectsState = {
    create: boolean
}

export default class extends React.Component<ProjectsProps, ProjectsState> {

    constructor(props) {
        super(props);

        this.state = {
            create: false,
        };
    }

    handleCreate = () => {
        this.setState({create: false});
    };

    handleReset = () => {
        this.setState({create: false});
    };

    render() {
        return <Scrollable flex={1} flexDirection="column">
            <Flex flexShrink={0} className="px-2 px-md-5 justify-content-between align-items-center border-bottom p-2">
                <PageTitle>Projekty</PageTitle>
                <StyledNewSearch onClick={() => this.setState({create: true})} className="font-weight-bold cursor-pointer">Pridať nový projekt</StyledNewSearch>
            </Flex>
            <Flex className="px-2 px-md-5" flexDirection="column">
                <Projects/>
            </Flex>
            {this.state.create && <CreateProject onCreate={this.handleCreate} onReset={this.handleReset}/>}
        </Scrollable>
    }
}
