//@flow

import * as React from "react";
import styled from 'styled-components';

import Highcharts from './Highcharts';
import {Flex} from '../../../components';

class LineChart extends React.PureComponent {

    render() {
        const {chart, title, series} = this.props;

        return <Highcharts chart={chart} options={{
            title: {
                text: title
            },
            xAxis: {
                type: 'datetime',
            },
            series: [
                {
                    data: series
                }
            ]
        }}/>
    }
}

export default styled(LineChart).attrs(props => ({theme: props.theme}))``;
