//@flow

import * as React from 'react';
import {Field} from 'redux-form';
import {connect} from 'react-redux';
import styled from 'styled-components';
import {Col} from "@bootstrap-styled/v4";

import {Flex} from '../../components';
import SelectField from "../../fields/SelectField";
import UnselectAll from "./UnselectAll";
import ExportButton from "./ExportButton";
import SearchResultsToolbar from "./components/SearchResultsToolbar";

const Separator = styled(Flex)`
    border-left: 1px solid ${props => props.theme['$silver']};
    margin: 0 ${props => props.theme['$spacers'][3]};
    height: 30px;
`;

type SearchResultsProps = {
    getSources: () => void,
    clearSubmitErrors: () => void,
    handleSubmit: () => void
}

type SearchResultsState = {
    export: boolean
}

class ResultBar extends React.Component<SearchResultsProps, SearchResultsState> {

    constructor(props) {
        super(props);

        this.state = {
            export: false
        }
    }

    handleToggleExport = () => {
        this.setState({export : !this.state.export});
    };

    render() {
        const {count, onSelectArticle} = this.props;

        return <>
            <Flex flexWrap="wrap" flexShrink={0} className="px-0 px-md-2 py-2 border-bottom">
                <Col xs={12} md="auto" className="align-items-center d-flex m-1">
                    <span className="text-smaller mx-1">{count} výsledkov</span>
                </Col>
                <Col xs={12} md="auto" className="align-items-center d-flex m-1">
                    <Field name="orderBy" component={SelectField} formGroup={{className: null}} options={[
                        {value: {orderBy: 'date', direction: 'desc'}, label: 'Zoradiť podľa dátumu - zostupne'},
                        {value: {orderBy: 'date', direction: 'asc'}, label: 'Zoradiť podľa dátumu - vzostupne'},
                        {value: {orderBy: '_score', direction: 'desc'}, label: 'Zoradiť podľa relevancie - zostupne'},
                        {value: {orderBy: '_score', direction: 'asc'}, label: 'Zoradiť podľa relevancie - vzostupne'}
                    ]}/>
                </Col>
                <Col xs={12} md="auto" className="d-none d-sm-flex m-1">
                    <Separator className="d-none d-md-flex"/>
                    <ExportButton onClick={this.handleToggleExport} />
                    <UnselectAll/>
                </Col>
            </Flex>
            {this.state.export && <SearchResultsToolbar onCancel={this.handleToggleExport} onSelectArticle={onSelectArticle}/>}
        </>
    }
}

ResultBar = connect(
    state => {
        return {
            count: state.search.meta.count || 0,
        }
    }
)(ResultBar);

export default ResultBar;
