//@flow
import * as React from 'react';
import {formValueSelector} from 'redux-form';
import type {FieldProps} from 'redux-form';
import {connect} from "react-redux";
import {Modal, ModalBody} from '@bootstrap-styled/v4';

import SourcesFilter from '../../../../components-v3/SourcesFilter';
import {Icon} from '../../../../components';
import {Input} from '../../../../components-v3';

type SourcesFieldProps = FieldProps | {
};

type SourcesFieldState = {
    open: boolean
}

class SourcesField extends React.Component<SourcesFieldProps, SourcesFieldState> {

    constructor(props: SourcesFieldProps) {
        super(props);

        this.state = {
            open: false
        }
    }

    handleToggle = () => {
        this.setState({
            open: !this.state.open
        });
    };

    handleChange = value => {
        const { input: { onChange }} = this.props;

        onChange(value);
    };

    handleClose = () => {
        this.setState({
            open: false
        })
    };

    get selected() {
        const {selected, count} = this.props;

        return 0 !== selected ? selected + '/' + count : '';
    }

    render() {
        const {input} = this.props;

        return <>
            <Input id="sources-button" onClick={this.handleToggle}>
                <Icon type="history" size={15}/>
                <span className="ml-2"><strong>Zdroje</strong> {this.selected}</span>
            </Input>
            <Modal name="filter-sources" isOpen={this.state.open} toggle={this.handleToggle} onClosed={this.handleClose} size="lg">
                <ModalBody>
                    <SourcesFilter onChange={this.handleChange} input={input} onClose={this.handleClose}/>
                </ModalBody>
            </Modal>
        </>
    }
}

export default connect(
    state => {
        return {
            count: state.sources.items.length,
            selected: formValueSelector('search')(state, 'filter.sources').length
        }
    }
)(SourcesField);