//@flow

import * as React from 'react';

import {SvgButton} from "../../components-v3";
import {Tooltip} from "../../components";

type UpdateButtonProps = {
    id: string,
    active: boolean,
    onClick: () => void
}

export default ({id, active, onClick}: UpdateButtonProps) => <>
    <SvgButton id={`update-button-${id}`} className="m-2" name="ikonka nastavenia.png" active={active} width={25} height={25} onClick={onClick}/>
    <Tooltip target={`update-button-${id}`} placement="bottom" color="cloudy-blue">Upraviť projekt</Tooltip>
</>
