import {detectUnit, rmUnit} from '@bootstrap-styled/utils/lib/unitUtils';

const theme = {
    '$font-family-base': 'Roboto Condensed',
    '$font-size-base': '.94rem',
    '$line-height-base': '1.7rem',
    '$grid-gutter-width': '0px'
};

// colors
theme['$white'] = '#FFFFFF';
theme['$black'] = '#000000';
theme['$silver'] = '#CBCBCB';
theme['$seashell'] = '#F1F1F1';
theme['$yellow-orange'] = '#FAAF3A';
theme['$sushi'] = '#8BC53E';
theme['$domino'] = '#95805F';
theme['$dodger-blue'] = '#3AB2FA';
theme['$wild-strawberry'] = '#FA3A98';
theme['$red-orange'] = '#FA3A3A';

theme['$bg-silver'] = theme['$silver'];
theme['$bg-seashell'] = theme['$seashell'];

theme['$brand-primary'] = theme['$yellow-orange'];

//spacing
theme['$spacer'] = '1.25rem';
const detectedUnit = detectUnit(theme['$spacer']);
theme['$spacers'] = {
    0: 0,
    1: (rmUnit(theme['$spacer']) * 0.25) + detectedUnit,
    2: (rmUnit(theme['$spacer']) * 0.5) + detectedUnit,
    3: theme['$spacer'],
    4: (rmUnit(theme['$spacer']) * 1.5) + detectedUnit,
    5: (rmUnit(theme['$spacer']) * 2.5) + detectedUnit,
};

//components
theme['$border-radius'] = '0';

theme['$modal-backdrop-bg'] = theme['$white'];
theme['$border-radius-lg'] = '0';
theme['$modal-content-border-color'] = theme['$black'];
theme['$modal-lg'] = '80%';
theme['$modal-md'] = '80%';
theme['$modal-inner-padding'] = theme['$spacers'][3];

theme['$link-decoration'] = 'none';

//fonts
theme['$font-size-sm'] = '.81rem';

theme['$font-size-h1'] = '2rem';
theme['$font-size-h2'] = '1.5rem';
theme['$font-size-h3'] = '1.2rem';


//buttons
theme['$btn-padding-x'] = theme['$spacers'][3];
theme['$btn-padding-y'] = theme['$spacers'][2];
theme['$btn-danger-color'] = '#f00';

//link
theme['$link-hover-decoration'] = 'none';

theme['$input-border-color'] = theme['$silver'];
theme['$input-btn-border-width'] = '2px';

theme['$font-weight-medium'] = 500;
theme['$font-weight-semi-bold'] = 600;
theme['$font-weight-bold'] = 700;
theme['$label-font-weight'] = theme['$font-weight-bold'];

//headings
theme['$headings-line-height'] = '1.2';
theme['$headings-font-weight'] = theme['$font-weight-bold'];

//tooltip
theme['$tooltip-opacity'] = 1;

//z-index
theme['$zindex-tooltip'] = '2100';

export default theme;