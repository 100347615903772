// @flow

import * as React from 'react';
import {connect} from "react-redux";
import styled from 'styled-components';
import {Col} from "@bootstrap-styled/v4";

import {Flex} from "../../components";
import {getCharts} from "../../queries";
import {TopSources, TopSourceTypes, MentionsInTime, KeywordsCloud, TopAuthors} from "./ChartInstances";

const StyledChart = styled(Col)`
`;

class Chart extends React.PureComponent {

    render() {
        const {chart, startAt, endAt} = this.props;

        return <StyledChart xs={12} md={6} xl={4} className="px-0 py-2 px-md-2">
            {'mentions-in-time' === chart.type && <MentionsInTime chart={chart} startAt={startAt} endAt={endAt}/>}
            {'top-source-types' === chart.type && <TopSourceTypes chart={chart} startAt={startAt} endAt={endAt}/>}
            {'top-sources' === chart.type && <TopSources chart={chart} startAt={startAt} endAt={endAt}/>}
            {'top-authors' === chart.type && <TopAuthors chart={chart} startAt={startAt} endAt={endAt}/>}
            {'keywords-cloud' === chart.type && <KeywordsCloud chart={chart} startAt={startAt} endAt={endAt}/>}
            {/*{'line' === chart.type && <LineChart chart={chart} title={chart.name}/>}*/}
            {/*{'column' === chart.type && <BarSeriesChart chart={chart} series={[{data: [4, 5, 6]}]} legend={['A', 'B']}/>}*/}
        </StyledChart>;
    }
}

class Charts extends React.PureComponent {

    componentDidMount() {
        const {getCharts} = this.props;

        getCharts();
    }

    render() {
        const {charts, startAt, endAt} = this.props;

        return <Flex flexWrap="wrap">
            {charts.items.map(chart => <Chart key={chart.id} chart={chart} startAt={startAt} endAt={endAt}/>)}
        </Flex>
    }
}

Charts = connect(
    (state, props) => ({
        charts: state.charts
    }),
    (dispatch, props) => ({
        getCharts: () => dispatch(getCharts({project: props.project}))
    })
)(Charts);

export default Charts;