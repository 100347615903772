//@flow

import PropTypes from 'prop-types';
import styled, { keyframes } from 'styled-components';

import { seagull, seagull25 } from "../variables";

const loader = keyframes`
    from { transform: rotate(0deg) }
    to { transform: rotate(360deg) }
`;

const Loader = styled.div`
    width: ${props => 'sm' === props.size ? '12px' : 'lg' === props.size ? '32px' : '24px'}; 
    height: ${props => 'sm' === props.size ? '12px' : 'lg' === props.size ? '32px' : '24px'};
    border-radius: 50%;
    position: relative;
    animation: ${loader} 1s linear 1s infinite;
    border: 3px solid ${seagull25};
    border-top-color: ${seagull};
    margin: auto;
`;

Loader.propTypes = {
    size: PropTypes.oneOf(['sm', 'lg'])
};

export default Loader;