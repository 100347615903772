import React, {useState} from 'react';
import {useLocation} from 'react-router-dom';
import {SearchContext} from "../contexts";
import moment from "moment";

const SearchProvider = ({children}) => {
    const {search} = useLocation();
    const params = new URLSearchParams(search);

    const [filter, setFilter] = useState({
        filter: {
            query: params.get('query') || '',
            sources: params.get('sources')?.split(',').map(source => parseInt(source)) || [],
            date: {
                type: 'date',
                from: params.get('from') || moment().subtract(1, 'd').format('YYYY-MM-DD'),
                to: params.get('to') || moment().subtract(1, 'd').format('YYYY-MM-DD')
            }
        },
        orderBy: {orderBy: '_score', direction: 'desc'}
    });

    return <SearchContext.Provider value={{filter, setFilter}}>
        {children}
    </SearchContext.Provider>
}

export default SearchProvider;
