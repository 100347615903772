//@flow

import * as React from 'react';
import type {FieldProps} from 'redux-form';

import SelectField from "./SelectField";

type RelativeDateFieldProps = {

} & FieldProps

export default class extends React.PureComponent<RelativeDateFieldProps> {

    get options() {
        return [
            {value: {startAt: 'today', endAt: 'today'}, label: 'Dnes'},
            {value: {startAt: '-1 day', endAt: '-1 day'}, label: 'Včera'},
            {value: {startAt: '-7 days', endAt: 'today'}, label: 'Posledných 7 dní'},
            {value: {startAt: 'last week', endAt: 'last week +6 days'}, label: 'Minulý týžden'},
            {value: {startAt: 'this week', endAt: 'this week +6 days'}, label: 'Tento týžden'},
            {value: {startAt: 'first day of last month', endAt: 'last day of last month'}, label: 'Minulý mesiac'},
            {value: {startAt: 'first day of this month', endAt: 'last day of this month'}, label: 'Tento mesiac'},
        ];
    }

    render() {
        const {input, ...rest} = this.props;

        return <SelectField options={this.options} {...input} {...rest}/>
    }
}
