import * as React from "react";
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';

import {auth} from "../../queries";
import {GRAPHQL_RECEIVE} from "../../actions/sso";

class Auth extends React.PureComponent {

    componentWillMount() {
        const { auth } = this.props;

        auth();
    }

    render() {
        return null
    }
}

Auth = connect(
    (state, props) => ({}),
    (dispatch, props) => {
        return {
            auth: () => {
                dispatch({
                    name: 'login',
                    type: GRAPHQL_RECEIVE,
                    payload: props.match.params.token
                });

                const url = new URLSearchParams(props.location.search);
                if ('' !== url.toString()) {
                    props.history.push(`/new?${url.toString()}`);
                } else {
                    props.history.push(`/new`);
                }
            }
        }
    }
)(Auth);

export default withRouter(Auth);
