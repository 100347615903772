import React from 'react';
import {FormFeedback, FormGroup, Input, Label} from "@bootstrap-styled/v4";
import type {FieldProps} from 'redux-form';
import styled from "styled-components";

const StyledLabel = styled(Label)`
    font-weight: ${props => props.theme['$label-font-weight']};
    text-transform: uppercase;
`;

const StyledFormGroup = styled(FormGroup).attrs({className: 'd-flex flex-column my-3'})``;

export default ({ input, meta, label, ...rest }: FieldProps) => {
    return <StyledFormGroup color={meta.touched && meta.error && 'danger'}>
        {label && <StyledLabel>{label}</StyledLabel>}
        <Input {...input} {...rest}/>
        {meta.touched && <FormFeedback>{meta.error}</FormFeedback>}
    </StyledFormGroup>;
}