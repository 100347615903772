import graphql from "../actions/sso";
import gql from "graphql-tag";

export default variables => graphql('login', 'login', gql`mutation Login(
    $username: String!,
    $password: String!,
) {
    login(
        username: $username, 
        password: $password
    )
}`, 'mutation', variables)
