import * as React from 'react';
import styled from "styled-components";

import {Svg} from "../../../components-v3";
import {Flex} from "../../../components";

const StyledKeywords = styled(Flex).attrs({className: 'font-weight-light'})`
    font-size: .83rem;
    line-height: 1.2rem;
    overflow: hidden;
    text-overflow: ellipsis;
`;

type KeywordsProps = {
    keywords: string,
    short: ?boolean
}

export default class extends React.PureComponent<KeywordsProps> {

    get keywords() {
        const { keywords, short = false } = this.props;

        if (short) {
            const items = keywords.split(', ');
            7 < items.length ? items.splice(7, items.length, '...') : items.splice(7, items.length);
            return items.join(', ');
        }
        return keywords;
    }

    render() {
        return <StyledKeywords alignItems="center">
            <Svg name="klucik.svg" width={16} height={10} className="mr-2"/> <span>{this.keywords}</span>
        </StyledKeywords>
    }
}