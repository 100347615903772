//@flow
import * as React from 'react';
import classnames from 'classnames';
import styled from 'styled-components';
import {Link} from 'react-router-dom';

export default styled(({active, ...rest}) => <Link {...rest} />).attrs(props => ({className: classnames("d-flex mx-2 text-center cursor-pointer text-uppercase align-items-center",
    props.active ? 'font-weight-bold' : 'font-weight-light'
)}))`
    color: ${props => props.active ? props.theme['$yellow-orange'] : props.theme['$black']};
    text-decoration: none;
`;

