//@flow

import * as React from 'react';
import styled from 'styled-components';

import {Svg} from ".";
import {Flex, Tooltip} from "../components";

const StyledHeaderInfo = styled(Flex)`
    font-size: ${props => props.theme['$font-size-sm']}
`;

type HeaderItemProps = {
    name: string,
    label: string,
    children: any
}

export default class extends React.PureComponent<HeaderItemProps> {

    render() {
        const {name, label, children} = this.props;

        const id = Math.random().toString().substring(2, 9);

        return <StyledHeaderInfo id={`header-info-${id}`} className="mr-3">
            <Svg name={name} width={16} className="mr-1"/>
            {label && <Tooltip target={`header-info-${id}`} placement="bottom" color="cloudy-blue">{label}</Tooltip>}
            {children}
        </StyledHeaderInfo>
    }
}
