// @flow

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@bootstrap-styled/v4';

const colors = ['primary', 'secondary', 'cloudy-blue', 'pale-gray'];

export default class extends PureComponent<ButtonProps> {

    static propTypes = {
        onClick: PropTypes.func,
        children: PropTypes.any.isRequired,
        color: PropTypes.oneOf(colors)
    };

    render() {
        const { color, onClick, children } = this.props;

        return <Button color={color} onClick={onClick}>{children}</Button>
    }
}
