// @flow

import * as React from 'react';
import classnames from 'classnames';
import {Button} from '@bootstrap-styled/v4';

import {Tooltip} from '../../../../../components';

type SearchButtonProps = {
    disabled: boolean,
    onClick: () => void
}

export default class extends React.PureComponent<SearchButtonProps> {

    get classnames() {
        const { disabled } = this.props;

        return classnames('m-1 font-weight-bold', { disabled: disabled });
    }

    render(): React.Node {
        const {disabled, ...rest} = this.props;

        return <>
            <Button id="search-button" color="primary" className={this.classnames} {...rest}>Vyhľadať</Button>
            {disabled && <Tooltip target="search-button" placement="bottom" color="cloudy-blue">Nemáte zvolený zdroj alebo kľúčové slová</Tooltip>}
        </>
    }
}
