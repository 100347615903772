//@flow

import * as React from 'react';
import {Modal, ModalHeader, ModalBody, ModalFooter, Button} from "@bootstrap-styled/v4";

type HeaderItemProps = {
    title: string,
    onConfirm: () => void,
    onCancel: () => void,
    children: any
}

export default class extends React.PureComponent<HeaderItemProps> {

    render() {
        const {title, onConfirm, onCancel, children} = this.props;

        return <Modal isOpen={true}>
            <ModalHeader>{title}</ModalHeader>
            <ModalBody>{children}</ModalBody>
            <ModalFooter>
                <Button color="secondary" onClick={onCancel}>Zrušiť</Button>
                <Button onClick={onConfirm}>Potvrdiť</Button>
            </ModalFooter>
        </Modal>
    }
}
