import * as React from 'react';
import {withRouter} from "react-router-dom";

import MenuItem from "../../components-v3/MenuItem";

class Projects extends React.PureComponent {

    render() {
        const {location} = this.props;

        return <MenuItem to="/projects" active={-1 !== location.pathname.indexOf('projects')}>Projekty</MenuItem>
    }
}

export default withRouter(Projects);