import { combineReducers } from 'redux';

import {GRAPHQL_REQUEST, GRAPHQL_RECEIVE, GRAPHQL_ERROR, GRAPHQL_CLEAR} from '../actions/graphql';
import {LOGOUT_REQUEST} from "../actions/logout";

export default root => {

    return (state = {}, action) => {

        const {name} = action;

        if ((name !== root) && (-1 === ['@@redux/INIT', LOGOUT_REQUEST].indexOf(action.type))) {
            return state;
        }


        const isFetching = (state = false, action) => {
            switch (action.type) {
                case GRAPHQL_REQUEST:
                    return true;
                case GRAPHQL_RECEIVE:
                case GRAPHQL_ERROR:
                    return false;
                default:
                    return state;
            }
        };

        const valid = (state = false, action) => {
            switch (action.type) {
                case GRAPHQL_RECEIVE:
                    return true;
                default:
                    return state;
            }
        };

        const initialized = (state = false, action) => {
            switch (action.type) {
                case GRAPHQL_CLEAR:
                    return false;
                case GRAPHQL_RECEIVE:
                    return true;
                default:
                    return state;
            }
        };

        const pristine = (state = true, action) => {
            switch (action.type) {
                case GRAPHQL_CLEAR:
                    return true;
                case GRAPHQL_REQUEST:
                case GRAPHQL_RECEIVE:
                case GRAPHQL_ERROR:
                    return false;
                default:
                    return state;
            }
        };

        const items = (state = [], action) => {
            switch (action.type) {
                case GRAPHQL_CLEAR:
                    return [];
                case GRAPHQL_RECEIVE:
                    if (0 === action.payload.meta.count) {
                        return [];
                    }
                    const result = state.slice();
                    action.payload.data && action.payload.data.forEach((article, index) => {
                        result[index + action.payload.meta.offset] = article
                    });

                    return result;
                default:
                    return state;
            }
        };

        const meta = (state = {count: 0}, action) => {
            switch (action.type) {
                case GRAPHQL_CLEAR:
                    return {count: 0};
                case GRAPHQL_RECEIVE:
                    return action.payload.meta;
                default:
                    return state;
            }
        };

        return combineReducers({
            isFetching,
            valid,
            initialized,
            pristine,
            items,
            meta
        })(state, action);
    }
}