//@flow

import * as React from 'react';
import styled from "styled-components";
import {CLIPPING_URL, CLIPPING_URL_LARGE, CLIPPING_URL_MEDIUM, CLIPPING_URL_SMALL} from "../../config";

const StyledImg = styled.img.attrs({className: 'cursor-pointer', alt: ''})`
    display: block;
    ${props => props.size && `width: ${props.size}px;`}
    ${props => props.size && `height: ${props.size}px;`}
    object-fit: cover;
    object-position: 0 0;
    ${props => props.selected && `border: 3px solid ${props.theme['$sushi']};`}
`;

type ClippingProps = {
    src: string,
    type?: "small" | "medium" | "large",
    selected?: boolean,
    size?: number,
    onClick?: () => void
}

export default class extends React.Component<ClippingProps> {

    constructor(props) {
        super(props);

        this.state = {
            error: false
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.src !== prevProps.src) {
            this.setState({error: false});
        }
    }

    get isImage(): boolean {
        const { src } = this.props;

        return src ? !['.mp3', '.mp4'].includes(src.substr(-4)) : true;
    }

    get isVideo(): boolean {
        const { src } = this.props;

        return src ? ['.mp4'].includes(src.substr(-4)) : true;
    }

    get isAudio(): boolean {
        const { src } = this.props;

        return src ? ['.mp3'].includes(src.substr(-4)) : true;
    }

    get source() {
        const { src, type, author, page } = this.props;

        if (this.isImage) {
            return ("small" === type ? CLIPPING_URL_SMALL :
                    ("medium" === type ? CLIPPING_URL_MEDIUM :
                            ("large" === type ? CLIPPING_URL_LARGE : "")
                    )
            ) + src;
        } else if (this.isVideo) {
            // if (-1 === author?.indexOf('DocOffset')) {
                return CLIPPING_URL + src;
            // } else {
            //     return CLIPPING_URL + src.replace('.mp4', `_${page}.mp4`);
            // }
        } else {
            return CLIPPING_URL + src;
        }
    }

    render() {
        const { src, type, fallback = require('../../assets/svg/no-image.svg'), onClick, ...rest } = this.props;

        const props = {
            ...rest,
            alt: this.source,
            src: this.state.error ? fallback : this.source,
            onClick: this.state.error ? null : onClick
        };

        if (this.isImage) {
            return <StyledImg onError={() => this.setState({error: true})} {...props}/>
        } else if (this.isVideo) {
            return <a href={this.source} target="_blank">
                <StyledImg onError={() => this.setState({error: true})} {...props} onClick={null} src={require('../../assets/img/video.png')} />
            </a>
        } else if (this.isAudio) {
            return <a href={this.source} target="_blank">
                <StyledImg onError={() => this.setState({error: true})} {...props} onClick={null} src={require('../../assets/img/audio.png')}/>
            </a>
        } else {
            return <a href={this.source} target="_blank">
                <StyledImg onError={() => this.setState({error: true})} {...props} onClick={null}/>
            </a>
        }
    }
}
