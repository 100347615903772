//@flow

import * as React from 'react';
import styled, {css} from 'styled-components';
import {Flex} from '../components';

type GridItemType = {
    area: string,
    xs: object,
    sm: object,
    md: object,
    ls: object,
    xl: object
}

const areaMixin = css`
    grid-area: ${props => props.area};
    
    ${props => 'undefined' !== typeof props.xs && `@media only screen and (min-width: 768px) {
        grid-area: ${props.xs.area};
    }`}
`;

const GridItem: React.ComponentType<GridItemType> = styled(Flex)`
    ${props => 'undefined' !== typeof props.area && areaMixin}
`;


const templateColumnsMixin = props => css`
    ${'undefined' !== typeof props.templateColumns && `grid-template-columns: ${props.templateColumns}`}
`;

const templateRowsMixin = props => css`
    ${'undefined' !== typeof props.templateRows && `grid-template-rows: ${props.templateRows}`}
`;

const columnGapMixin = props => css`
    ${'undefined' !== typeof props.columnGap && `column-gap: ${props.columnGap}`}
`;

const gridMixin = props => css`
    ${templateColumnsMixin(props)}
    ${templateRowsMixin(props)}
    ${columnGapMixin(props)}
`;

const xsMixin = props => css`@media only screen and (min-width: 768px) {
    ${'undefined' !== typeof props.xs && gridMixin(props.xs)}
}`;

type GridType = {
    templateColumns: string,
    templateRows: string,
    columnGap: string
};

const Grid = styled(({templateColumns, templateRows, columnGap, component = 'div', ...rest}) => {
    const Tag = component;

    return <Tag {...rest}/>
})`
    display: grid;

    ${props => gridMixin(props)}
    ${props => xsMixin(props)}
`;


export default Grid;

export {
    GridItem
}
