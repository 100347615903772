import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import styled from 'styled-components';

const Counter  = styled.div`
    color: ${props => props.theme['$white']};
    background: ${props => props.theme['$sushi']};
    border-radius: 12px;
    padding: 0 ${props => props.theme['$spacers'][2]};
`;

class SelectedArticlesCounter extends PureComponent {

    render() {
        const { selected, ...rest } = this.props;

        return <Counter {...rest}>{selected}</Counter>;
    }
}

export default connect(
    state => {
        const selector = formValueSelector('export');
        return {
            selected: selector(state, 'articles')?.length || 0
        }
    }
)(SelectedArticlesCounter)
