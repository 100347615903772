//@flow

import * as React from 'react';
import {connect} from 'react-redux';

import {Flex} from "../../components";
import Project from './Project';
import {getProjects} from "../../queries";

type ProjectsProps = {
    projects: {
        items: []
    },
    getProjects: () => Promise<any>
}

type ProjectsState = {
    create: boolean
}

class Projects extends React.Component<ProjectsProps, ProjectsState> {

    componentDidMount() {
        const {getProjects} = this.props;

        getProjects();
    }

    render() {
        const {projects} = this.props;

        return <Flex flex="1 0 auto" flexDirection="column">
            {projects.items.map(project => <Project key={project.id}
                                                    id={project.id}
                                                    name={project.name}
                                                    query={project.query}
                                                    sources={project.sources}
                                                    startAt={project.startAt}
                                                    endAt={project.endAt}
                                                    absoluteStartAt={project.absoluteStartAt}
                                                    absoluteEndAt={project.absoluteEndAt}
            />)}
        </Flex>
    }
}

type ConnectedProjectsProps = {

}

const ConnectedProjects:React.AbstractComponent<ConnectedProjectsProps> = connect(
    (state, props: ConnectedProjectsProps) => {
        return {
            projects: state.projects
        }
    },
    (dispatch, props: ConnectedProjectsProps) => {
        return {
            getProjects: () => dispatch(getProjects())
        }
    }
)(Projects);

export default ConnectedProjects;