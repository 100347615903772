import {graphql} from "../actions";
import gql from "graphql-tag";

export default () => graphql('sources', 'sources', gql`query Sources {
    sources {
        id
        name
        type
        scope
        periodicity
        sourceGroup {
            id
            name
        }
    }
}`)