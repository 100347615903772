//@flow

import * as React from 'react';
import {Label, Button, FormGroup, Form} from "@bootstrap-styled/v4";
import {Field, reduxForm} from "redux-form";
import type {FormProps} from 'redux-form';
import styled from 'styled-components';

import {Flex, Tooltip} from "../../components";
import {Sources} from "../../components-v3";
import InputField from '../../fields/InputField';
import {getProjects} from "../../queries";
import RelativeDateField from '../../fields/RelativeDateField';

const StyledLabel = styled(Label)`
    font-weight: ${props => props.theme['$label-font-weight']};
    text-transform: uppercase;
`;

const StyledFormGroup = styled(FormGroup).attrs({className: 'd-flex flex-column my-3'})``;

const StyledSources = styled(Flex)`
    border: 2px solid ${props => props.theme['$silver']};
    background: ${props => props.theme['$white']};
`;

type ProjectProps = {
    onReset: () => void
} & FormProps

class Project extends React.PureComponent<ProjectProps> {

    handleReset = () => {
        const {reset, onReset} = this.props;

        reset();
        onReset();
    };

    render() {
        const {invalid, handleSubmit} = this.props;

        return <Flex flexDirection="column" className="p-3">
            <Form>
            <Field name="name" component={InputField} label="Názov projektu"/>
            <Field name="query" component={InputField} type="textarea" rows={5} label="Vyhladávací reťazec"/>
            <StyledFormGroup>
                <StyledLabel>Zdroje</StyledLabel>
                <StyledSources flex={1}>
                    <Field name="sources" component={Sources}/>
                </StyledSources>
            </StyledFormGroup>
            <Field name="date" component={RelativeDateField} label="Dátum" />
            {/*<Label>Uzivatelia</Label>*/}
            {/*<Field name="users" component="input"/>*/}
            <Flex className="my-3">
                <Button id="save-button" color="primary" disabled={invalid} onClick={handleSubmit} className="mr-3">Uložiť projekt</Button>
                {invalid && <Tooltip target={`save-button`} placement="bottom" color="cloudy-blue">Nevyplnili ste všetky potrebné údaje</Tooltip>}
                <Button color="secondary" onClick={this.handleReset}>Zrušiť</Button>
            </Flex>
            </Form>
        </Flex>
    }
}

type ProjectFormProps = {
    save: (values: {}) => Promise<() => any>,
    onReset: () => void
}

const ProjectForm:React.AbstractComponent<ProjectFormProps> = reduxForm({
    onSubmit: (values, dispatch, props: ProjectFormProps) => {
        props.save({
            name: values.name,
            query: values.query,
            sources: values.sources,
            startAt: values.date.startAt,
            endAt: values.date.endAt
        })
            .then(() => dispatch(getProjects()));
    },
    initialValues: {
        date: {startAt: 'today', endAt: 'today'},
    },
    validate: values => {
        const errors = {};

        if (!values.name) {
            errors.name = 'Toto pole je povinné';
        }
        if (!values.query) {
            errors.query = 'Toto pole je povinné';
        }
        if (!values.date) {
            errors.date = 'Toto pole je povinné';
        }

        return errors
    }
})(Project);

export default ProjectForm;