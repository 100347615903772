import * as React from 'react';
import { connect } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroller';
import {Field, formValueSelector} from 'redux-form';
import gql from 'graphql-tag';
import moment from 'moment/moment';

import ArticleListItem from '../ArticlesListItem';
import {graphql} from '../../../../actions';
import withExportForm from "../withExportForm";
import {Loader, Scrollable} from '../../../../components';

class ArticlesListItemsField extends React.Component {

    handleSelect = id => {
        const { input: { onChange, value }} = this.props;

        let index = value.indexOf(id);
        if (-1 === index) {
            onChange([
                ...value,
                id
            ])
        } else {
            onChange([
                ...value.slice(0, index),
                ...value.slice(index + 1)
            ])
        }
    };

    render() {
        const { selected, articles, loadMore, count, input, isFetching, isInitialized, onSelect } = this.props;

        if (!Array.isArray(input.value)) {
            input.value = [];
        }

        if (!isInitialized) {
            return <Loader size="lg" className="my-3 mx-auto"/>;
        }

        return <Scrollable flex={1} justifyContent="center">
            <InfiniteScroll
                pageStart={1}
                loadMore={loadMore}
                hasMore={articles.length < count}
                useWindow={false}
                threshold={500}
                className="flex-grow-1"
            >
                {articles.map((article, index) =>
                    article && <ArticleListItem key={index}
                                                counter={index}
                                                onClick={() => onSelect(article)}
                                                checked={-1 !== input.value.indexOf(article.id)}
                                                selected={selected?.id === article.id}
                                                onSelect={this.handleSelect}
                                                id={article.id}
                                                date={article.date}
                                                score={article.score}
                                                author={article.author}
                                                source={article.source}
                                                keywords={article.keywords}
                                                service={article.service}
                                                title={article.title}
                                                url={article.url}
                                                clippings={article.clippings}
                    />
                )}
                {isFetching ? <Loader size="lg" className="my-3 mx-auto"/> : null}
            </InfiniteScroll>
        </Scrollable>
    }
}

ArticlesListItemsField = connect(
    (state, props) => {
        return {
            isFetching: state.search.isFetching,
            isInitialized: state.search.initialized
        }
    }
)(ArticlesListItemsField);

class ArticlesListItems extends React.PureComponent {

    render() {
        return <Field name="articles" component={ArticlesListItemsField} {...this.props}/>
    }
}

ArticlesListItems = withExportForm(ArticlesListItems);

class ArticlesList extends React.PureComponent {

    render() {
        const { articles, count, onSelect, loadMore } = this.props;

        return <ArticlesListItems articles={articles} onSelect={onSelect} loadMore={loadMore} count={count}/>
    }
}

export default connect(
    state => {
        const selector = formValueSelector('search');
        return {
            articles: state.search.items || [],
            count: state.search.meta.count || 0,
            values: selector(state, 'filter', 'orderBy', 'direction'),
        }
    },
    dispatch => {
        return {
            loadMore: (values, page) => {
                const limit = 25;
                const query = values.filter.query.replace(/"/g, '\\"');
                // return dispatch(search({
                //     query: query,
                //     from: moment(values.filter.date.from).format('YYYY-MM-DD'),
                //     to: moment(values.filter.date.to).format('YYYY-MM-DD'),
                //     source: values.filter.sources,
                //     orderBy: values.orderBy,
                //     direction: values.direction,
                //     offset: Number((page - 1) * limit),
                //     limit: limit
                // }))
                return dispatch(graphql('search', 'search', gql`query Search {
                    search(
                        query: "${query}",
                        type: ${values.filter.date.type},
                        from: "${moment(values.filter.date.from).format('YYYY-MM-DD')}",
                        to: "${moment(values.filter.date.to).add(1, 'd').format('YYYY-MM-DD')}",
                        source: [${values.filter.sources.join(',')}]
                        orderBy: ${values.orderBy.orderBy},
                        direction: ${values.orderBy.direction},
                        offset: ${Number((page - 1) * limit)}, 
                        limit: ${limit}
                    ) {
                        data {
                            id
                            title
                            service
                            author
                            url
                            content
                            date
                            page
                            source {
                                name
                                sourceGroup {
                                    id
                                }
                            }
                            clippings {
                                id
                                url
                            }
                            keywords
                            score
                        }
                        meta {
                            count
                            offset
                            limit
                        }
                      }
                    }`
                ));
            }
        }
    },
    (stateProps, dispatchProps, props) => {
        return Object.assign({}, props, stateProps, dispatchProps, {
            loadMore: page => {
                dispatchProps.loadMore(stateProps.values, page);
            }
        });
    }
)(ArticlesList)