// @flow

import {graphql} from "../actions";
import gql from "graphql-tag";

export type UpdateProps = {
    id: string,
    name: string,
    query: string,
    startAt: string,
    endAt: string
};

export default (variables: UpdateProps) => graphql('updateProject', 'updateProject', gql`mutation UpdateProject($id: String!, $name: String!, $query: String!, $sources: [Int!], $startAt: String!, $endAt: String!) {
    updateProject(
        id: $id,
        name: $name,
        query: $query,
        sources: $sources,
        startAt: $startAt,
        endAt: $endAt
    ) {
        id
    }
}`, 'mutation', variables)