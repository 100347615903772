import * as React from 'react';
import { connect } from 'react-redux';
import { Field, formValueSelector } from 'redux-form';
import styled from 'styled-components';
import {Col} from "@bootstrap-styled/v4";

import {Flex} from '../../../../components';
import DatePickerField from '../../../../ui/DatePickerField';
import SearchButton from './components/SearchButton';
import SourcesField from './SourcesField';
import QueryField from "./QueryField";

const StyledWrapper = styled(Flex).attrs({className: 'px-2 px-md-5 py-2 border-bottom', alignItems: 'center', flexWrap: 'wrap', flex: 1})`
    background: ${props => props.theme['$seashell']}
`;

type SearchBarProps = {

}

type SearchBarState = {
    toolbar: ['search', 'export']
}

class SearchBar extends React.Component<SearchBarProps, SearchBarState> {

    handleSearch = e => {
        const { invalid, onSearch } = this.props;

        if (!invalid) {
            onSearch(e);
        }
    };

    render(): React.Node {
        const { invalid } = this.props;


        return <StyledWrapper>
            <Col xs={12} md="auto" className="flex-grow-1">
                <Field name="filter.query" component={QueryField} onSubmit={this.handleSearch}/>
            </Col>
            <Col xs={12} md="auto">
                <Field name="filter.date" component={DatePickerField}/>
            </Col>
            <Col xs={12} md="auto">
                <Field name="filter.sources" component={SourcesField}/>
            </Col>
            <Col xs={12} md="auto">
                <SearchButton disabled={invalid} onClick={this.handleSearch}/>
            </Col>
        </StyledWrapper>
    }
}

export default connect(
    state => ({})
)(SearchBar);