//@flow

import * as React from 'react';
import {connect} from 'react-redux';
import moment from "moment";

import Search from '../Search';
import Articles from '../Search/components/Articles';
import {getQuery, search} from "../../queries";
import {clear} from "../../actions";

type HistorySearchProps = {
    query: string,
    from: string,
    to: string,
    sources: [number],
    search: ({
        query: string,
        startAt: string,
        endAt: string,
        sources: [number]
    }) => Promise<any>,
    searchHistory: () => void
}

class HistorySearch extends React.PureComponent<HistorySearchProps> {

    componentDidMount() {
        const {searchHistory} = this.props;

        searchHistory();
    }

    componentDidUpdate(prevProps: Props, prevState: State, prevContext: *): * {
        if (this.props.match.params.id !== prevProps.match.params.id) {
            const {searchHistory} = this.props;

            searchHistory();
        }
    }

    render() {
        const {query, from, to, sources} = this.props;

        return <Search initialValues={
            {
                filter: {
                    query: query,
                    sources: sources,
                    date: {
                        from: from,
                        to: to
                    }
                },
                orderBy: {orderBy: '_score', direction: 'desc'}
            }
        }>
            <Articles/>
        </Search>
    }
}
export default connect(
    (state, props) => {
        return {
            query: state.query.data?.query,
            from: moment(state.query.data?.startAt).format('YYYY-MM-DD'),
            to: moment(state.query.data?.endAt).format('YYYY-MM-DD'),
            sources: state.query.data?.sources.map(source => source.id) || []
        }
    },
    (dispatch, props) => {
        return {
            searchHistory: () => {
                dispatch(clear('search'));
                dispatch(clear('article'));
                return dispatch(getQuery({id: props.match.params.id}))
                    .then(response => dispatch(search({
                        query: response.payload.query,
                        startAt: moment(response.payload.startAt).format('YYYY-MM-DD'),
                        endAt: moment(response.payload.endAt).add(1, 'd').format('YYYY-MM-DD'),
                        sources: response.payload.sources.map(source => source.id)
                    })))
            },
        }
    }
)(HistorySearch);
