// @flow

import * as React from 'react';
import Moment from 'react-moment';
import styled from 'styled-components';

import highlight from '../../../helpers/highlight';
import Checkbox from '../../../components/Checkbox';
import Keywords from './Keywords';
import {Flex} from '../../../components';
import {Clipping} from "../../../components-v3";
import {Svg} from "../../../components-v3";
import {gallery} from "../../../variables";
import {CLIPPING_URL_SMALL} from '../../../config';

const Dl = styled.dl.attrs({className: 'd-flex mt-2 mb-0'})`
    font-size: .8rem;
    line-height: 1.2rem;
    color: gray;
`;

const Dd = styled.dd.attrs({className: 'ml-2 mr-3 my-0'})`
    font-weight: bold;
`;

const Dt = styled.dt`
    font-weight: normal;
`;

type HeaderProps = {
    id: string,
    source: {
        name: string
    },
    author: string,
    service: string
}

class Header extends React.PureComponent<HeaderProps> {

    render() {
        const {id, source, author, service, score, date} = this.props;

        return <Dl>
            <Dt><Svg name="ikonka kniha.svg" width={16} height={16}/></Dt>
            <Dd id={`header-source-${id}`}>{source?.name}</Dd>
            <Dt className="d-none d-md-block"><Svg name="autor.svg" width={16} height={16}/></Dt>
            <Dd className="d-none d-md-block" id={`header-author-${id}`}>{-1 === author.indexOf('DocOffset') ? author : ''}</Dd>
            <Dt className="d-none d-md-block"><Svg name="adresar.svg" width={16} height={16}/></Dt>
            <Dd className="d-none d-md-block" id={`header-service-${id}`}>{service}</Dd>
            <Dt><Svg name="ikonka hodinky.svg" width={16} height={16}/></Dt>
            <Dd id={`header-date-${id}`}>
                {3 !== source?.sourceGroup.id && <StyledDate format="DD. MM. YYYY" className="text-gray">{date}</StyledDate>}
                {3 === source?.sourceGroup.id && <StyledDate format="DD. MM. YYYY HH:mm" className="text-gray">{date}</StyledDate>}
            </Dd>
            <StyledScore>{score.toFixed(2)}</StyledScore>
            {/*<Tooltip target={`header-source-${id}`} placement="bottom" color="cloudy-blue">Zdroj</Tooltip>*/}
            {/*<Tooltip target={`header-author-${id}`} placement="bottom" color="cloudy-blue">Autor</Tooltip>*/}
            {/*<Tooltip target={`header-service-${id}`} placement="bottom" color="cloudy-blue">Servis</Tooltip>*/}
            {/*<Tooltip target={`header-date-${id}`} placement="bottom" color="cloudy-blue">Dátum</Tooltip>*/}
        </Dl>
    }
}

const StyledItem = styled(Flex).attrs({className: 'cursor-pointer px-1 px-md-3 py-3'})`
    border-bottom: 1px solid ${gallery};
    background: ${props => props.selected ? props.theme['$bg-seashell'] : 'transparent'}
`;

const StyledCheckbox = styled(Flex)`
    width: 1rem;
    display: none;
    
    @media only screen and (min-width: 576px) {
        display: flex;
    }

`;

const StyledDate = styled(Moment)`
    font-size: .8rem;
`;

const StyledArticle = styled(Flex).attrs({className: 'mx-sm-2'})`
`;

const StyledScore = styled.div.attrs({className: 'font-weight-semi-bold'})`
    font-size: .8rem;
`;

type ArticlesListItemProps = {
    title: string,
    url: string,
    score: string,
    author: string,
    keywords: string,
    source: {
        name: string
    },
    service: string,
    date: string
}

export default class extends React.PureComponent<ArticlesListItemProps> {

    render(): React.Node {
        const { id, title, score, author, keywords, source, service, date, clippings, counter, onClick, onSelect, checked, selected } = this.props;

        return <StyledItem selected={selected}>
            <StyledCheckbox onClick={() => onSelect(id)}>
                <Checkbox checked={checked} />
            </StyledCheckbox>
            <StyledArticle flex="1" flexDirection="column" onClick={onClick}>
                <Flex>
                    <Clipping src={clippings?.[0]?.url} type="small" size={52}/>
                    <h2 className="m-0 ml-2 font-weight-bold" dangerouslySetInnerHTML={{__html: (counter + 1) + '. ' + highlight(title)}}/>
                </Flex>
                <Header id={id} source={source} author={author} service={service} score={score} date={date}/>
                <Keywords keywords={keywords}/>
            </StyledArticle>
        </StyledItem>
    }
}
