import { combineReducers } from 'redux';

import {GRAPHQL_REQUEST, GRAPHQL_RECEIVE, GRAPHQL_ERROR, GRAPHQL_CLEAR} from '../actions/graphql';
import {LOGOUT_REQUEST} from "../actions/logout";

export default root => {

    return (state = {}, action) => {
        const { name } = action;

        if ((name !== root) && (-1 === ['@@redux/INIT', LOGOUT_REQUEST].indexOf(action.type))) {
            return state;
        }

        const isFetching = (state = false, action) => {
            switch (action.type) {
                case GRAPHQL_REQUEST:
                    return true;
                case GRAPHQL_RECEIVE:
                case GRAPHQL_ERROR:
                    return false;
                default:
                    return state;
            }
        };

        const valid = (state = false, action) => {
            switch (action.type) {
                case GRAPHQL_RECEIVE:
                    return true;
                default:
                    return state;
            }
        };

        const pristine = (state = true, action) => {
            switch (action.type) {
                case GRAPHQL_CLEAR:
                    return true;
                case GRAPHQL_REQUEST:
                case GRAPHQL_RECEIVE:
                case GRAPHQL_ERROR:
                    return false;
                default:
                    return state;
            }
        };

        const data = (state = null, action) => {
            switch (action.type) {
                case GRAPHQL_CLEAR:
                    return null;
                case GRAPHQL_RECEIVE:
                    return action.payload;
                default:
                    return state;
            }
        };

        return combineReducers({
            isFetching,
            valid,
            pristine,
            data
        })(state, action);
    }
}
