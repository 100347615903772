import {graphql} from "../actions";
import gql from "graphql-tag";

export default variables => graphql('query', 'query', gql`query Query($id: String!) {
    query(id: $id) {
        id
        query
        startAt
        endAt
        sources {
            id
        }
    }
}`, 'query', variables)