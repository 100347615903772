// @flow

import {graphql} from "../actions";
import gql from "graphql-tag";

export type RemoveProps = {
    id: string,
};

export default (variables: RemoveProps) => graphql('removeChart', 'removeChart', gql`mutation RemoveChart($id: String!) {
    deleteChart(id: $id)
}`, 'mutation', variables)