// @flow

import * as React from 'react';
import {connect} from "react-redux";

import ChartForm from "./ChartForm";
import {getCharts, updateChart} from '../../queries';

class UpdateChart extends React.PureComponent {

    render() {
        return <ChartForm {...this.props}/>
    }
}

UpdateChart = connect(
    (state, props) => ({
        initialValues: {
            name: props.chart.name,
            type: props.chart.type,
        }
    }),
    (dispatch, props) => ({
        onSubmit: values => dispatch(updateChart({
            id: props.chart.id,
            ...values,
            project: props.chart.project.id
        }))
            .then(() => dispatch(getCharts({project: props.chart.project.id})))
    })
)(UpdateChart);

export default UpdateChart;