// @flow

import * as React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import {Modal, ModalHeader, ModalBody, ModalFooter, Button, H1} from '@bootstrap-styled/v4';
import moment from 'moment';

import highlight from '../../../helpers/highlight';
import type { Article } from '../../../types/Article';
import {Scrollable, Flex} from '../../../components';
import {Clipping} from "../../../components-v3";
import Keywords from './Keywords';
import {CLIPPING_URL, CLIPPING_URL_MEDIUM, CLIPPING_URL_SMALL} from "../../../config";

const Dl = styled.dl.attrs({className: 'd-flex m-0 font-weight-light'})`
    font-size: .83rem;
`;
const Dt = styled.dt.attrs({className: 'd-inline m-0 mr-1'})``;
const Dd = styled.dd.attrs({className: 'd-inline m-0 mr-4'})``;

const StyledH1 = styled(H1)`
    line-height: 1.156;
`;

const H3 = styled.h3.attrs({className: 'text-uppercase'})``;

type HeaderProps = {
    score: string,
    date: string,
    source: {
        name: string
    },
    author: string,
    service: string,
    page: string
}

class Header extends React.PureComponent<HeaderProps> {

    render() {
        const {source, author, service, page, date, ave, grp} = this.props;

        return <Dl className="d-flex">
            <Dt>Zdroj:</Dt>
            <Dd>{source?.name}</Dd>
            <Dt>Autor:</Dt>
            <Dd>{-1 === author.indexOf('DocOffset') ? author : ''}</Dd>
            <Dt>Servis:</Dt>
            <Dd>{service}</Dd>
            <Dt>Datum:</Dt>
            {3 !== source.sourceGroup.id && <Dd>{moment(date, 'YYYY-MM-DD').format('DD. MM. YYYY')}</Dd>}
            {3 === source.sourceGroup.id && <Dd>{moment(date, 'YYYY-MM-DD HH:mm').format('DD. MM. YYYY HH:mm')}</Dd>}
            <Dt>Strana:</Dt>
            <Dd>{page}</Dd>
            <Dt>AVE:</Dt>
            <Dd>{ave}</Dd>
            <Dt>GRP:</Dt>
            <Dd>{source.grp}</Dd>
        </Dl>
    }
}

type ClippingsProps = {
    clippings: [string]
}

class Clippings extends React.PureComponent<ClippingsProps> {

    state = {
        open: false,
        url: null
    };

    handleToggle = () => {
        const {clippings} = this.props;

        this.setState({
            open: !this.state.open,
            url: !this.state.open ? clippings?.[0].url : null
        });
    };

    handleChange = url => {
        this.setState({url: url});
    };

    render() {
        const {clippings, author, page} = this.props;

        if (0 === clippings.length) {
            return <Clipping src="https://via.placeholder.com/104" size={104} />
        }

        return <>
            {clippings.map((clipping, index) => <Clipping key={clipping.url} src={clipping.url} author={author} page={page} type="medium" onClick={this.handleToggle} size={104}/>)}
            <Modal isOpen={this.state.open} toggle={this.handleToggle} size="lg">
                <ModalHeader toggle={this.handleToggle}>Clipping</ModalHeader>
                <ModalBody>
                    <Flex>
                        <Flex direction="column" className="mr-3">
                            {clippings.length
                                ? clippings.map(clipping => <Clipping key={clipping.url} selected={this.state.url === clipping.url} src={clipping.url} type="small" onClick={() => this.handleChange(clipping.url)} size={52}/>)
                                : <Clipping key="placeholder" src="https://via.placeholder.com/52"/>}
                        </Flex>
                        <Scrollable style={{maxHeight: '400px'}}>
                            <div>
                                {this.state.url && <img style={{'maxWidth': '100%', 'objectFit': 'contain'}} src={CLIPPING_URL + this.state.url} alt=""/>}
                            </div>
                        </Scrollable>
                    </Flex>
                </ModalBody>
                <ModalFooter>
                    {this.state.url && <Button tag="A" href={CLIPPING_URL + this.state.url} target="_blank" color="primary" onClick={this.handleToggle}>Stiahnuť</Button>}
                    <Button color="secondary" onClick={this.handleToggle}>Zatvoriť</Button>
                </ModalFooter>
            </Modal>
        </>
    }
}

type ContentProps = {
    content: string
}

class Content extends React.PureComponent<ContentProps> {

    render() {
        const {content} = this.props;

        return <div dangerouslySetInnerHTML={{__html: highlight(content)}}/>;
    }
}

const StyledTitle = styled(Flex).attrs({className: 'mb-3', flexShrink: 0})`
`;

const StyledHeader = styled(Flex).attrs({className: 'p-3 mb-3'})`
    background: ${props => props.theme['$seashell']}
`;

const StyledScore = styled.div.attrs({className: 'font-weight-semi-bold'})`
    font-size: 1.1rem;
`;

type ArticleDetailProps = {
    article: Article,
};

class ArticleDetail extends React.PureComponent<ArticleDetailProps> {

    render(): React.Node {
        const { article } = this.props;

        if (!article) {
            return null;
        }

        return <Scrollable flexDirection="column">
            <StyledTitle>
                <Clippings clippings={article.clippings} author={article.author} page={article.page} />
                <Flex flex={1}>
                    <StyledH1 className="m-0 ml-3 flex-grow-1" dangerouslySetInnerHTML={{__html: highlight(article.title)}}/>
                </Flex>
            </StyledTitle>
            <StyledHeader flexShrink={0} justifyContent="space-between">
                <Flex flexDirection="column">
                    <Header score={article.score} date={article.date} source={article.source} author={article.author} service={article.service} page={article.page} ave={article.ave} />
                    <Keywords keywords={article.keywords}/>
                </Flex>
            </StyledHeader>
            <Flex flexShrink={0} justifyContent="space-between">
                <StyledScore/>
                <div>share</div>
            </Flex>
            {article.url && <p>
                <a href={article.url} className="text-cloudy-blue"
                   target="_blank"
                   rel="noopener noreferrer"
                   dangerouslySetInnerHTML={{__html: highlight(article.url)}}/>
            </p>}
            <H3>{article.subtitle}</H3>
            <Content content={article.content} />
        </Scrollable>
    }
}

export default connect(
    state => ({
        // article: state.article.data,
    })
)(ArticleDetail)