// @flow

import {graphql} from "../actions";
import gql from "graphql-tag";

export type UpdateProps = {
    id: string,
    name: string,
    query: string,
    startAt: string,
    endAt: string
};

export default (variables: UpdateProps) => graphql('updateChart', 'updateChart', gql`mutation UpdateChart(
    $id: String!,
    $name: String,
    $project: String,
    $type: String,
    $settings: String
) {
    updateChart(
        id: $id,
        input: {
            name: $name,
            project: $project,
            type: $type,
            settings: $settings
        }
    ) {
        id
    }
}`, 'mutation', variables)