import {graphql} from "../actions";
import gql from "graphql-tag";

export default variables => graphql('topSources', 'topSources', gql`query TopSources(
    $chart: String!
    $startAt: String!
    $endAt: String!
) {
    topSources(chart: $chart, startAt: $startAt, endAt: $endAt) {
        key
        doc_count
    }
}`, 'query', variables)