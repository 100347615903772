//@flow

import {reduxForm} from "redux-form";
import {saveAs} from "file-saver";

import {createExport} from "../../../queries";
import {BACKEND_URL} from "../../../config";

export default WrappedComponent => {

    return reduxForm({
        form: 'export',
        initialValues: {
            type: 21,
            maxResults: 10,
            articles: [],
            dateType: 'date',
        },
        destroyOnUnmount: false,
        onSubmit: (values, dispatch, props) => {
            const { date, ...filter } = props.data.filter;
            return dispatch(createExport({
                query: filter.query,
                dateType: date.type,
                startAt: date.from + ' 00:00:00',
                endAt: date.to + ' 23:59:59',
                type: values.type,
                maxResults: values.maxResults,
                sources: filter.sources,
                articles: values.articles
            }))
            .then(response => {
                saveAs(`${BACKEND_URL}/export/${response.payload.id}/download`, response.payload.export)
            });
        }
    })(WrappedComponent);

}
