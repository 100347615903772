//@flow

import * as React from 'react';
import styled from 'styled-components';

export default styled(({width, height, name, ...rest}) => <div {...rest} />)`
    background: transparent url('${props => require(`../assets/svg/${props.name}`)}') no-repeat center center/100% content-box content-box;
    ${props => props.width && `width: ${props.width}px;`}
    ${props => props.height && `height: ${props.height}px;`}
`;
