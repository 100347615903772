import {graphql} from "../actions";
import gql from "graphql-tag";

export default variables => graphql('createProject', 'createProject', gql`mutation CreateProject(
    $name: String!, 
    $query: String!, 
    $sources: [Int!],
    $startAt: String!, 
    $endAt: String!
) {
    createProject(
        name: $name,
        query: $query,
        sources: $sources,
        startAt: $startAt,
        endAt: $endAt
    ) {
        id
    }
}`, 'mutation', variables)