// @flow

import * as React from 'react';
import {connect} from 'react-redux';

import {BarSeriesChart} from "../ChartTypes";
import {topSourceTypes} from "../../../queries";

class TopSourceTypes extends React.PureComponent {

    componentDidMount() {
        const {getData} = this.props;

        getData();
    }

    render() {
        const {chart, sourceGroups, data} = this.props;

        if (data.isFetching) {
            return null;
        }

        const legend = data.items.map(item => sourceGroups.items.find(sourceGroup => sourceGroup.id === parseInt(item.key)));
        return <BarSeriesChart chart={chart}
                               series={[{data: data.items.map(item => parseInt(item.doc_count))}]}
                               legend={legend.map(item => item?.name)}
                               title={chart.name}
        />
    }
}

TopSourceTypes = connect(
    (state, props) => ({
        sourceGroups: state.sourceGroups,
        data: state.topSourceTypes
    }),
    (dispatch, props) => ({
        getData: () => dispatch(topSourceTypes({
            chart: props.chart.id,
            startAt: props.startAt,
            endAt: props.endAt
        }))
    })
)(TopSourceTypes);

export default TopSourceTypes;