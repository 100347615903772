import {graphql} from "../actions";
import gql from "graphql-tag";

export default variables => graphql('project', 'project', gql`query Project($id: String!) {
    project(id: $id) {
        id
        name
        query
        sources {
            id
            name
        }
        startAt
        endAt
        absoluteStartAt
        absoluteEndAt
    }
}`, 'query', variables)