//@flow

import * as React from 'react';
import styled from 'styled-components';

import ArrayField from '../../fields/ArrayField';
import { Checkbox, Flex } from "../../components";

const StyledSource = styled(Flex)`
    flex: 0 0 50%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: ${props => props.theme['$spacers'][1]};
    
    @media only screen and (min-width: 768px) {
        flex: 0 0 16.6%;
    }

`;

type SourceProps = {
    value: [],
    onChange: () => void
}

export default class extends React.PureComponent<SourceProps> {

    render() {
        const { source, value, onChange} = this.props;

        return <StyledSource>
            <ArrayField key={source.id} value={value} onChange={onChange} label={source.name} option={source.id} component={Checkbox}>{source.name}</ArrayField>
        </StyledSource>
    }
}
