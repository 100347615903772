//@flow

import * as React from 'react';
import styled, {css} from 'styled-components';
import classnames from 'classnames';

type FlexType = {
    flexDirection: 'row' | 'column',
    flex: boolean|number|string,
    flexGrow: ?number,
    flexShrink: ?number,
    flexBasis: ?string,
    justifyContent: 'normal' | 'space-between',
    alignItems: 'flex-start' | 'flex-end' | 'center' | 'stretch' | 'baseline',
    alignContent: 'flex-start' | 'flex-end' | 'space-between' | 'space-around' | 'stretch'
}

const flexMixin = css`
    ${props => 'boolean' === typeof props.flex ? `flex: 1;` : `flex: ${props.flex};`}
`;

const Flex: React.ComponentType<FlexType> = styled.div`
    display: flex;
    min-height: 0;
    ${props => 'undefined' !== typeof props.flex && flexMixin}
    ${props => 'undefined' !== typeof props.flexDirection && `flex-direction: ${props.flexDirection}`};
    ${props => 'undefined' !== typeof props.flexGrow && `flex-grow: ${props.flexGrow}`};
    ${props => 'undefined' !== typeof props.flexShrink && `flex-shrink: ${props.flexShrink}`};
    ${props => 'undefined' !== typeof props.flexBasis && `flex-basis: ${props.flexBasis}`};
    ${props => 'undefined' !== typeof props.justifyContent && `justify-content: ${props.justifyContent}`};
    ${props => 'undefined' !== typeof props.flexWrap && `flex-wrap: ${props.flexWrap}`};
    align-items: ${props => props.alignItems ? props.alignItems : "normal"};
    align-content: ${props => props.alignContent ? props.alignContent : "normal"};
`;

export default Flex;

const breakpoint = (breakpoint, prefix = null) => {
    const classes = [];

    if ('number' === typeof breakpoint) {
        breakpoint = {col: breakpoint};
    }

    Object.keys(breakpoint).forEach(key => {
        if (!Array.isArray(breakpoint[key])) {
            breakpoint[key] = [breakpoint[key]];
        }
        breakpoint[key].forEach(item => {
            let name = key;
            if (null !== prefix) {
                name += '-' + prefix;
            }
            if (null !== item) {
                name += '-' + item;
            }
            classes.push(name);
        })
    });

    return classes;
};

class Box extends React.PureComponent {

    get className() {
        const {xs = [], sm = [], md = [], lg = [], xl = [], className = ''} = this.props;

        return classnames(
            className,
            breakpoint(xs),
            breakpoint(sm, 'sm'),
            breakpoint(md, 'md'),
            breakpoint(lg, 'lg'),
            breakpoint(xl, 'xl')
        );
    }

    render() {
        const {className, as = 'div', xs, sm, md, lg, xl, ...rest} = this.props;

        const Tag = as;

        return <Tag {...rest} className={this.className}/>
    }
}

const Flexbox = styled(Box)`
    display: flex;
    min-height: 0;
`;

export {
    Box,
    Flexbox
} ;