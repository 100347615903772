// @flow

import * as React from 'react';
import {connect} from 'react-redux';

import {BarSeriesChart} from "../ChartTypes";
import {topSources} from "../../../queries";

class TopSources extends React.PureComponent {

    componentDidMount() {
        const {getData} = this.props;

        getData();
    }

    render() {
        const {chart, sources, data} = this.props;

        if (data.isFetching) {
            return null;
        }

        const legend = data.items.map(item => sources.items.find(source => source.id === parseInt(item.key)));
        //@todo fix legend. remove conditional chaining
        return <BarSeriesChart chart={chart}
                               series={[{data: data.items.map(item => parseInt(item.doc_count))}]}
                               legend={legend.map(item => item?.name)}
                               title={chart.name}
        />
    }
}

TopSources = connect(
    (state, props) => ({
        sources: state.sources,
        data: state.topSources
    }),
    (dispatch, props) => ({
        getData: () => dispatch(topSources({
            chart: props.chart.id,
            startAt: props.startAt,
            endAt: props.endAt
        }))
    })
)(TopSources);

export default TopSources;