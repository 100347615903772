import {graphql} from "../actions";
import gql from "graphql-tag";

export default variables => graphql('charts', 'charts', gql`query Charts(
    $project: String!
) {
    charts(project: $project) {
        id
        name
        type
        project {
            id
        }
    }
}`, 'query', variables)