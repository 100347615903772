import * as React from 'react';
import {Flex} from "./components";
import styled, {css} from "styled-components";
import moment from "moment";
import Moment from 'react-moment';

import DateNames from './DateNames';
import Dates from './Dates';
import {aquamarineBlue, dustyGray} from "./variables";
import {paleGray} from "../../variables";

const Year = styled.div`
    color: ${dustyGray};
    width: 20%;
    font-weight: ${props => props.theme['$font-weight-bold']};
    margin-top: ${props => props.theme['$spacers'][1]};
    margin-bottom: ${props => props.theme['$spacers'][1]};
    text-align: center;
`;

const StyledYears = styled(Flex)`
    flex-wrap: wrap;
`;

class Years extends React.PureComponent {

    handleChange = value => {
        const {date, onChange} = this.props;

        onChange(date.clone().year(value));
    };

    render() {
        const years = [];
        for (let i = moment().format('YYYY'); i >= 1990; i--) {
            years[years.length] = i;
        }
        return <StyledYears>
            {years.map(year => <Year key={year} onClick={() => this.handleChange(year)}>{year}</Year>)}
        </StyledYears>
    }
}

const Month = styled.div`
    font-size: .875rem;
    color: ${dustyGray};
    width: 25%;
    margin-top: ${props => props.theme['$spacers'][4]};
    margin-bottom: ${props => props.theme['$spacers'][4]};
    text-align: center;
`;

const StyledMonths = styled(Flex)`
    margin-top: ${props => props.theme['$spacers'][4]};
    margin-bottom: ${props => props.theme['$spacers'][4]};
    flex-wrap: wrap;
`;

class Months extends React.PureComponent {

    handleChange = value => {
        const {date, onChange} = this.props;

        onChange(date.clone().month(value));
    };

    render() {
        const months = [];
        for (let i = 0; i <= 11; i++) {
            months[months.length] = i;
        }
        return <StyledMonths>
            {months.map(month => <Month key={month} onClick={() => this.handleChange(month)}>{moment().month(month).format('MMM')}</Month>)}
        </StyledMonths>
    }
}

const DatePicker = styled.div`
    width: 320px;
`;

const datePartActiveMixin = css`
    border-bottom: 1px solid ${aquamarineBlue} 
`;

const DatePart = styled(({active, ...rest}) => <Moment {...rest} />)`
    font-weight: 600;
    ${props => props.active && datePartActiveMixin};
    padding-left: ${props => props.theme['$spacers'][3]};
    padding-right: ${props => props.theme['$spacers'][3]};
`;

const Label = styled.div`
    font-size: .875rem;
`;

const Date = styled(Flex)`
    font-size: 1.1rem;
    border-bottom: 1px solid ${paleGray}
    margin-top: ${props => props.theme['$spacers'][3]};
    margin-bottom: ${props => props.theme['$spacers'][3]};
`;

export default class extends React.PureComponent {

    constructor(props) {
        super(props);

        this.state = {
            mode: 'date',
            hover: null
        }
    }

    handleSetMode = mode => {
        this.setState({mode: mode})
    };

    handleHover = date => {
        this.setState({ hover: date })
    };

    handleChangeYear = date => {
        this.handleChange(date);

        this.setState({mode: 'month'});
    };

    handleChangeMonth = date => {
        this.handleChange(date);

        this.setState({mode: 'date'});
    };

    handleChange = date => {
        const {onChange} = this.props;

        onChange(date);
    };

    render() {
        const {date, from, to, label} = this.props;

        return <DatePicker>
            <Label>{label}</Label>
            <Date>
                <DatePart active={'date' === this.state.mode} format="DD" onClick={() => this.handleSetMode('date')}>{date}</DatePart>/
                <DatePart active={'month' === this.state.mode} format="MMM" onClick={() => this.handleSetMode('month')}>{date}</DatePart>/
                <DatePart active={'year' === this.state.mode} format="YYYY" onClick={() => this.handleSetMode('year')}>{date}</DatePart>
            </Date>
            {'year' === this.state.mode && <Years date={date} onChange={this.handleChangeYear}/>}
            {'month' === this.state.mode && <Months date={date} onChange={this.handleChangeMonth}/>}
            {'date' === this.state.mode && <DateNames/>}
            {'date' === this.state.mode && <Dates active={date}
                                                  from={from}
                                                  to={to}
                                                  hover={this.state.hover}
                                                  onChange={this.handleChange}
                                                  onHover={this.handleHover}
                                                  expanded={true}
            />}
        </DatePicker>
    }
}