import ApolloClient from 'apollo-boost';
import {GRAPHQL_URL_AUTHORIZED} from "../config";

export const GRAPHQL_CLEAR = 'core/GRAPHQL_CLEAR';
export const GRAPHQL_REQUEST = 'core/GRAPHQL_REQUEST';
export const GRAPHQL_RECEIVE = 'core/GRAPHQL_RECEIVE';
export const GRAPHQL_ERROR = 'core/GRAPHQL_ERROR';

export default (name, root, query, type = 'query', variables = {}) => {
    return (dispatch, getState) => {
        dispatch({
            name: name,
            type: GRAPHQL_REQUEST,
        });

        const client = new ApolloClient({
            uri: GRAPHQL_URL_AUTHORIZED
        });
        if ('query' === type) {

            return client.query({
                query: query,
                variables: variables,
                context: {
                    headers: {
                        Authorization: 'Bearer ' + getState().login.data
                    }
                },
                errorPolicy: 'all'
            })
                .then(response => {
                    if (response.errors) {
                        throw response.errors;
                    }
                    return dispatch({
                        name: name,
                        type: GRAPHQL_RECEIVE,
                        payload: response.data[root]
                    });
                })
                .catch(error => {
                    dispatch({
                        name: name,
                        type: GRAPHQL_ERROR,
                        payload: error
                    });
                    throw error;
                })
            ;
        } else {
            return client.mutate({
                mutation: query,
                variables: variables,
                context: {
                    headers: {
                        Authorization: 'Bearer ' + getState().login.data
                    }
                }
            })
                .then(response => {
                    return dispatch({
                        name: name,
                        type: GRAPHQL_RECEIVE,
                        payload: response.data[root]
                    });
                })
                .catch(error => {
                    dispatch({
                        name: name,
                        type: GRAPHQL_ERROR,
                        payload: error
                    });
                    throw error;
                })
            ;
        }
    }
}

export const clear = (name) => {
    return {
        name: name,
        type: GRAPHQL_CLEAR
    }
};