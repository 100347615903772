// @flow

import * as React from 'react';
import {connect} from "react-redux";

import ChartForm from "./ChartForm";
import {createChart, getCharts} from '../../queries';

class CreateChart extends React.PureComponent {

    render() {
        return <ChartForm {...this.props}/>
    }
}

CreateChart = connect(
    (state, props) => ({}),
    (dispatch, props) => ({
        onSubmit: values => dispatch(createChart({
            ...values,
            project: props.project
        }))
            .then(() => dispatch(getCharts({project: props.project})))
    })
)(CreateChart);

export default CreateChart;