//@flow

import * as React from 'react';

export default class extends React.PureComponent {

    render() {
        return <img className="mx-2" src={require('../assets/img/logo-small.png')} alt="logo" style={{'height': '17px'}}/>
    }
}
