import * as React from 'react';
import styled from 'styled-components';

import Svg from "../Svg";
import {Tooltip} from "../../components";

const StyledSvg = styled(Svg)`
    margin-left: ${props => props.theme['$spacers'][1]};
    margin-right: ${props => props.theme['$spacers'][1]};
`;

export default class extends React.Component {

    render() {
        const {onSelect, onUnselect, onInvert} = this.props;

        const id1 = Math.random().toString().substring(2, 9);
        const id2 = Math.random().toString().substring(2, 9);
        const id3 = Math.random().toString().substring(2, 9);

        return <>
            <StyledSvg id={`select-button-${id1}`} name="ikonka potvrdit mala.svg" width={16} height={16} onClick={onSelect}/>
            <Tooltip target={`select-button-${id1}`} placement="top" color="cloudy-blue">Vybrať zdroje</Tooltip>
            <StyledSvg id={`invert-button-${id2}`} name="ikonka inverovat vyber.svg" width={16} height={16} onClick={onInvert}/>
            <Tooltip target={`invert-button-${id2}`} placement="top" color="cloudy-blue">Invertovať výber</Tooltip>
            <StyledSvg id={`unselect-button-${id3}`} name="ikonka zrusit mala.svg" width={16} height={16} onClick={onUnselect}/>
            <Tooltip target={`unselect-button-${id3}`} placement="top" color="cloudy-blue">Zrušiť výber</Tooltip>
        </>
    }
}
