//@flow

import * as React from 'react';
import {reduxForm, SubmissionError, clearSubmitErrors, Field} from 'redux-form';
import moment from 'moment';
import {connect} from 'react-redux';
import {ToastMessageAnimated} from "react-toastr";
import {Link} from 'react-router-dom';
import styled from 'styled-components';
import {Collapse, Navbar, NavbarToggler} from "@bootstrap-styled/v4";

import SearchBar from './components/SearchBar';
import {clear} from '../../actions';
import {Flex} from '../../components';
import {PageTitle} from '../../components-v3';
import {getSources, search} from "../../queries";

const StyledNewSearch = styled(Link).attrs({className: ''})`
    color: ${props => props.theme['$yellow-orange']};
    text-decoration: none;
`;

const Separator = styled(Flex)`
    border-left: 1px solid ${props => props.theme['$silver']};
    margin: 0 ${props => props.theme['$spacers'][3]};
    height: 30px;
`;

const StyledNavbar = styled(Navbar)`
    padding: ${props => props.theme['$spacers'][0]} !important;
`;

const StyledNavbarToggler = styled(NavbarToggler)`
    span {
        background-image: url(${require('../../assets/svg/ikonka nastavenia.png')}) !important
    }
`;

type SearchResultsProps = {
    getSources: () => void,
    clearSubmitErrors: () => void,
    handleSubmit: () => void
}

type SearchResultsState = {
    export: boolean
}

class SearchResults extends React.Component<SearchResultsProps, SearchResultsState> {

    constructor(props) {
        super(props);

        this.state = {
            navbar: false,
        }
    }

    componentDidMount() {
        const { getSources } = this.props;

        getSources();
    }

    handleClearError = () => {
        const {clearSubmitErrors} = this.props;

        clearSubmitErrors();
    };

    render() {
        const {change, invalid, resetQuery, children, error, handleSubmit} = this.props;

        return <Flex flex={1} flexDirection="column">
            <StyledNavbar toggleable="sm" className="flex-column align-items-stretch">
                <Flex flexShrink={0} className="p-2 px-md-5 justify-content-between align-items-center border-bottom">
                    <PageTitle>Vyhľadávanie</PageTitle>
                    <Flex className="align-items-center">
                        <StyledNewSearch to="/new" onClick={resetQuery} className="font-weight-bold">Nové vyhľadávanie</StyledNewSearch>
                        <StyledNavbarToggler onClick={() => this.setState({navbar: !this.state.navbar})}/>
                    </Flex>
                </Flex>
                <Collapse navbar={true} isOpen={this.state.navbar}>
                    <SearchBar onSearch={handleSubmit} change={change} invalid={invalid}/>
                </Collapse>
            </StyledNavbar>
            <Flex className="px-2 px-md-5" flex={1} flexDirection="column">
                {children}
            </Flex>
            {error && <div id="toast-container" className="toast-top-right">
                <ToastMessageAnimated title="Chyba pri vyhľadávaní" message={error} type="error" closeButton={true} onRemove={this.handleClearError}/>
            </div>}
        </Flex>
    }
}

SearchResults = connect(
    state => ({}),
    (dispatch, props) => {
        return {
            clearSubmitErrors: () => dispatch(clearSubmitErrors('search')),
            getSources: () => dispatch(getSources()),
            resetQuery: () => {
                props.reset();
                return dispatch(clear('search'))
            }

        }
    }
)(SearchResults);

const submit = (values, dispatch) => {
    dispatch(clear('search'));
    dispatch(clear('article'));
    return dispatch(search(values))
        .catch(errors => {
            throw new SubmissionError({_error: 'Pri vyhľadávaní sa vyskytla chyba'})
        });
};

export default reduxForm({
    form: 'search',
    validate: values => {
        const errors = {};
        if ((!values.filter.query) || (0 === values.filter.query.length)) {
            errors.filter = {query: 'Required'};
        }
        return errors;
    },
    onChange: (values, dispatch, props, previousValues) => {
        if ((values.orderBy.orderBy !== previousValues.orderBy.orderBy)
            || (values.orderBy.direction !== previousValues.orderBy.direction)
        ) {
            return submit({
                query: values.filter.query,
                type: values.filter.date.type,
                startAt: moment(values.filter.date.from).format('YYYY-MM-DD'),
                endAt: moment(values.filter.date.to).add(1, 'd').format('YYYY-MM-DD'),
                sources: values.filter.sources,
                orderBy: values.orderBy.orderBy,
                direction: values.orderBy.direction
            }, dispatch);
        }
    },
    onSubmit: (values, dispatch, props) => submit({
        query: values.filter.query,
        type: values.filter.date.type,
        startAt: moment(values.filter.date.from).format('YYYY-MM-DD'),
        endAt: moment(values.filter.date.to).add(1, 'd').format('YYYY-MM-DD'),
        sources: values.filter.sources,
        orderBy: values.orderBy.orderBy,
        direction: values.orderBy.direction
    }, dispatch),
    destroyOnUnmount: false,
    enableReinitialize: true,
})(SearchResults);

