import ApolloClient from 'apollo-boost';

import {GRAPHQL_URL_AUTHORIZED} from "../config";

export default (query, variables = {}) => {
    return (dispatch, getState) => {
        const client = new ApolloClient({
            uri: GRAPHQL_URL_AUTHORIZED
        });

        return client.query({
            query: query,
            variables: variables,
            context: {
                headers: {
                    Authorization: 'Bearer ' + getState().login.data
                }
            },
            errorPolicy: 'all'
        })
            .then(response => {
                if (response.errors) {
                    throw response.errors;
                }
                return response;
            })
        ;
    }
}
