//@flow

import * as React from 'react';
import {connect} from "react-redux";
import {change} from 'redux-form';

import {Checkbox} from '../../components';

type UnselectAllProps = {
    unselectAll: () => Promise<any>
}

class UnselectAll extends React.PureComponent<UnselectAllProps> {

    render() {
        const { unselectAll } = this.props;

        return <Checkbox onChange={unselectAll} className="mx-2" label="Odznaciť všetko"/>
    }
}

export default connect(
    state => ({}),
    dispatch => {
        return {
            unselectAll: () => dispatch(change('export', 'articles', []))
        }
    }
)(UnselectAll);
