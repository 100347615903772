import {graphql} from "../actions";
import gql from "graphql-tag";

export default variables => graphql('createChart', 'createChart', gql`mutation CreateChart(
    $name: String!, 
    $project: String!, 
    $type: String!,
    $settings: String
) {
    createChart(input: {
        name: $name,
        project: $project,
        type: $type,
        settings: $settings
    }) {
        id
    }
}`, 'mutation', variables)