import * as React from 'react';
import moment from 'moment';
import {Button} from '@bootstrap-styled/v4';
import styled from 'styled-components';

import {Flex} from './components';

const StyledButton = styled(Button)`
    margin: ${props => props.theme['$spacers'][1]} 0;
`;

class FastButton extends React.PureComponent {

    render() {
        const { children, onClick } = this.props;

        return <StyledButton color="secondary" onClick={onClick}>{children}</StyledButton>
    }
}

export default class extends React.PureComponent {

    handleChange = (from, to) => {
        const { onChange } = this.props;

        onChange(from, to);
    };

    render() {

        return <Flex flex={1} flexDirection="column">
            <FastButton onClick={() => this.handleChange(moment(), moment())}>Dnes</FastButton>
            <FastButton onClick={() => this.handleChange(moment().subtract(1, 'd'), moment().subtract(1, 'd'))}>Včera</FastButton>
            <FastButton onClick={() => this.handleChange(moment().subtract(6, 'd'), moment())}>Posledných 7 dní</FastButton>
            <FastButton onClick={() => this.handleChange(moment().subtract(1, 'M').add(1, 'd'), moment())}>Posledných 30 dní</FastButton>
            <FastButton onClick={() => this.handleChange(moment().subtract(1, 'M').startOf('month'), moment().subtract(1, 'M').endOf('month'))}>Predchádzajúci mesiac</FastButton>
            <FastButton onClick={() => this.handleChange(moment().startOf('y'), moment())}>Tento rok</FastButton>
            <FastButton onClick={() => this.handleChange(moment().subtract(1, 'y').startOf('year'), moment().subtract(1, 'y').endOf('year'))}>Predchádzajúci rok</FastButton>
        </Flex>;
    }

}