import React, {useContext, useEffect} from 'react';
import {connect} from 'react-redux';
import Search from '../Search';
import Articles from "../Search/components/Articles";
import Projects from '../Projects/Projects';
import {SearchProvider} from "../../providers";
import {SearchContext} from "../../contexts";
import moment from "moment";
import {clear} from "../../actions";
import {search} from "../../queries";

const NewSearch = ({pristine, search}) => {
    const {filter} = useContext(SearchContext);

    useEffect(() => {
        if (filter.filter.query) {
            search({
                query: filter.filter.query,
                startAt: filter.filter.date.from,
                endAt: filter.filter.date.to,
                sources: filter.filter.sources
            })
        }
    }, [filter.filter]);

    return <Search initialValues={filter}>
        {pristine ? <Projects /> : (
            <Articles/>
        )}
    </Search>
}

const NewSearchProvider = (props) => (
    <SearchProvider>
        <NewSearch {...props}/>
    </SearchProvider>
)

export default connect(
    state => {
        return {
            pristine: state.search.pristine
        }
    },
    dispatch => {
        return {
            search: ({query, startAt, endAt, sources}) => {
                dispatch(clear('search'));
                dispatch(clear('article'));
                return dispatch(search({
                    query: query,
                    type: 'date',
                    startAt: moment(startAt).format('YYYY-MM-DD'),
                    endAt: moment(endAt).add(1, 'd').format('YYYY-MM-DD'),
                    sources: sources
                }))
            }
        }
    }
)(NewSearchProvider);
