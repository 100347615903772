//@flow

import * as React from "react";

import Highcharts from './Highcharts';
import {Flex} from '../../../components';

class BarSeriesChart extends React.PureComponent {

    render() {
        const {chart, title, legend, series} = this.props;

        return <Highcharts chart={chart} options={{
            chart: {
                type: 'column',
                zoomType: 'x'
            },
            title: {
                text: title
            },
            xAxis: {
                categories: legend
            },
            series: series
        }}/>
    }
}

export default BarSeriesChart;