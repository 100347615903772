//@flow

import * as React from 'react';
import {connect} from 'react-redux';
import styled from 'styled-components';
import {H2} from "@bootstrap-styled/v4";
import moment from 'moment';
import Avatar from "react-avatar";
import {Link} from "react-router-dom";

import {HeaderInfo, Grid, GridItem} from "../../components-v3";
import UpdateProject from "./UpdateProject";
import SearchButton from "./SearchButton";
import RemoveButton from "./RemoveButton";
import UpdateButton from "./UpdateButton";

const StyledProject = styled(({selected, ...rest}) => <div {...rest}/>)`
    ${props => props.selected ? `background: ${props.theme['$seashell']}` : ''}
`;

const StyledLink = styled(Grid).attrs(props => ({
    component: Link,
    className: 'border-bottom py-3',
    templateColumns: 'min-content auto min-content',
    templateRows: 'auto auto auto',
    columnGap: props.theme['$spacers'][2],
    xs: {
        templateRows: 'auto auto'
    }
}))`
    color: ${props => props.theme['$black']};
    text-decoration: none;
`;

const StyledAvatar = styled(Avatar)`
    grid-area: 1 / 1 / 3 / 2;
`;

const StyledTitle = styled(H2)`
    grid-area: 1 / 2 / 2 / 3;
    margin-bottom: 0;
    font-weight: ${props => props.theme['$font-weight-bold']};
    :first-letter {
        text-transform: uppercase;
    }
`;

const StyledHeader = styled(GridItem).attrs({
    area: '3 / 1 / 4 / 4',
    xs: {
        area: '2 / 2 / 3 / 3'
    }
})``;

const StyledButtons = styled(GridItem).attrs({
    className: 'align-items-center',
    area: '1 / 3 / 3 / 4'
})``;

type ProjectProps = {
    id: string,
    name: string,
    query: string,
    sources: [],
    startAt: string,
    endAt: string,
    absoluteStartAt: string,
    absoluteEndAt: string,
    sourcesCount: number,
}

type ProjectState = {
    update: boolean
}

class Project extends React.Component<ProjectProps, ProjectState> {

    constructor(props) {
        super(props);

        this.state = {
            update: false
        }
    }

    toggleUpdate = e => {
        e.preventDefault();
        this.setState({update: !this.state.update});
    };

    handleUpdate = () => {
        this.setState({update: false})
    };

    handleReset = () => {
        this.setState({update: false})
    };

    render() {
        const {id, name, query, sources, startAt, endAt, absoluteStartAt, absoluteEndAt, sourcesCount} = this.props;

        return <StyledProject selected={this.state.update}>
            <StyledLink to={`/project/${id}`}>
                <StyledAvatar name={name} size={52}/>
                <StyledTitle>{name}</StyledTitle>
                <StyledHeader>
                    <HeaderInfo name="ikonka kniha.svg" label="Počet zdrojov">{sources.length} zdroj/ov z {sourcesCount}</HeaderInfo>
                    <HeaderInfo name="ikonka hodinky.svg" label="Dátumový rozsah">od {moment(absoluteStartAt).format('DD. MM. YYYY')} do {moment(absoluteEndAt).format('DD. MM. YYYY')}</HeaderInfo>
                    {/*<HeaderInfo name="ikonka terc.svg">X výsledkov</HeaderInfo>*/}
                </StyledHeader>
                <StyledButtons>
                    <UpdateButton id={id} active={this.state.update} onClick={this.toggleUpdate}/>
                    <SearchButton id={id}/>
                    <RemoveButton id={id}/>
                </StyledButtons>
            </StyledLink>
            {this.state.update && <UpdateProject id={id} name={name} query={query} sources={sources} startAt={startAt} endAt={endAt} onReset={this.handleReset} onUpdate={this.handleUpdate}/>}
        </StyledProject>
    }
}

export default connect(
    (state, props) => {
        return {
            sourcesCount: state.sources.items.length
        }
    }
)(Project);
