import * as React from 'react';

import { Flex } from './components';
import Date from './Date';
import Row from './Row';

type DatesProps = {
    active: boolean,
    from: any,
    to: any,
    hover: any,
    onClick: () => void,
    onHover: () => void
};

class Week extends React.PureComponent {

    get dates() {
        const {week} = this.props;

        let dates = [];
        for (let i = 0, date = week.clone(); i < 7; i++, date.add(1, 'd')) {
            dates.push(date.clone());
        }

        return dates;
    }

    render() {
        const {week, ...rest} = this.props;

        return <Row>
            {this.dates.map((date, index) => <Date key={index} date={date} {...rest}/>)}
        </Row>
    }
}

export default class extends React.PureComponent<DatesProps> {

    get start() {
        const { active } = this.props;

        return active.clone().startOf('month').startOf('week');
    }

    get end() {
        const { active } = this.props;

        return active.clone().endOf('month').endOf('week');
    }

    get weeks() {
        const {expanded, active} = this.props;

        const weeks = [];
        for (let week = this.start; week.isSameOrBefore(this.end); week.add(1, 'w')) {
            if (expanded || (week.isSameOrBefore(active) && week.clone().add(1, 'w').isAfter(active))) {
                weeks.push(week.clone());
            }
        }

        return weeks;
    }

    render(): React.Node {
        return <Flex flex={1} flexDirection="column">
            {this.weeks.map((week, index) => {
                return <Week key={index} week={week} {...this.props}/>
            })}
        </Flex>
    }
}
