import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import { reducer as uiReducer } from 'redux-ui';

import {LOGOUT_REQUEST} from '../actions/logout';
import dataReducer from './data';
import itemsReducer from './items';
import paginationReducer from './pagination';

const appReducer = combineReducers({
    ui: uiReducer,
    form: formReducer,

    article: dataReducer('article'),
    login: dataReducer('login'),
    sourceGroups: itemsReducer('sourceGroups'),
    sources: itemsReducer('sources'),
    queries: itemsReducer('queries'),
    projects: itemsReducer('projects'),
    project: dataReducer('project'),
    history: itemsReducer('history'),
    profile: dataReducer('profile'),
    search: paginationReducer('search'),
    query: dataReducer('query'),
    createExport: dataReducer('createExport'),
    charts: itemsReducer('charts'),

    topSources: itemsReducer('topSources'),
    topSourceTypes: itemsReducer('topSourceTypes'),
    mentionsInTime: itemsReducer('mentionsInTime'),
    keywordsCloud: itemsReducer('keywordsCloud'),
    topAuthors: itemsReducer('topAuthors')
});

const rootReducer = (state, action) => {
    if (LOGOUT_REQUEST === action.type) {
        state = undefined;
    }
    return appReducer(state, action);
};

export default rootReducer;
