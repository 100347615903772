//@flow

import * as React from 'react';
import {connect} from 'react-redux';

import {SvgButton, ConfirmationDialog} from "../../components-v3";
import {Tooltip} from "../../components";
import {getProjects, removeProject} from "../../queries";

type RemoveButtonProps = {
    id: string,
    onRemove: (id: string) => void
}

const RemoveButton = ({id, onRemove}: RemoveButtonProps) => {
    const [open, setOpen] = React.useState(false);

    return (<>
        <SvgButton id={`remove-button-${id}`} className="m-2" name="velky krizik sedy.png" width={25} height={25} onClick={e => {
            e.preventDefault();
            setOpen(true);
        }}/>
        <Tooltip target={`remove-button-${id}`} placement="bottom" color="cloudy-blue">Vymazať</Tooltip>
        {open && <ConfirmationDialog
            title="Vymazať projekt"
            onConfirm={() => {
                onRemove(id);
                setOpen(false);
            }}
            onCancel={() => setOpen(false)}
        >Naozaj si prajete vymazať tento projekt?</ConfirmationDialog>}
    </>)
};

export default connect(
    (state, props) => ({}),
    (dispatch, props) => {
        return {
            onRemove: id => dispatch(removeProject({id: id}))
                .then(() => dispatch(getProjects()))
        }
    }
)(RemoveButton)
