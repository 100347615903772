// @flow

import * as React from 'react';
import {connect} from 'react-redux';

import {BarSeriesChart} from "../ChartTypes";
import {topAuthors} from "../../../queries";

class TopAuthors extends React.PureComponent {

    componentDidMount() {
        const {getData} = this.props;

        getData();
    }

    render() {
        const {chart, data} = this.props;

        if (data.isFetching) {
            return null;
        }

        return <BarSeriesChart chart={chart}
                               series={[{data: data.items.map(item => parseInt(item.doc_count))}]}
                               legend={data.items.map(item => item.key)}
                               title={chart.name}
        />
    }
}

TopAuthors = connect(
    (state, props) => ({
        data: state.topAuthors
    }),
    (dispatch, props) => ({
        getData: () => dispatch(topAuthors({
            chart: props.chart.id,
            startAt: props.startAt,
            endAt: props.endAt
        }))
    })
)(TopAuthors);

export default TopAuthors;