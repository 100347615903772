//@flow

import * as React from 'react';
import {withRouter} from "react-router-dom";

import {SvgButton, MenuItem} from "../../components-v3";
import {Tooltip} from "../../components";

class Help extends React.PureComponent {

    render() {
        const {location} = this.props;

        return <MenuItem to={{pathname: location.pathname, state: {modal: 'help'}}}
                         active={'help' === location.state?.modal}
        >
            <SvgButton id="help-button" name="ikonka napoveda.svg" width={28} height={28}/>
            <Tooltip target="help-button" placement="top" color="cloudy-blue">Nápoveda</Tooltip>
        </MenuItem>
    }
}

export default withRouter(Help);
