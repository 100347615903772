//@flow

import * as React from 'react';
import {Button, Modal, ModalHeader, ModalBody, ModalFooter, NavItem, Nav, NavLink} from '@bootstrap-styled/v4';
import {withRouter} from "react-router-dom";
import styled from 'styled-components';

import {Flex} from "../components";

const StyledNavLink = styled(NavLink)`
    cursor: pointer;
`;

const StyledImg = styled.img`
    width: 100%;
`;

class Help extends React.PureComponent {

    constructor(props) {
        super(props);

        this.state = {
            tab: 'basics'
        }
    }

    handleTab = (tab) => {
        this.setState({tab: tab});
    };

    handleToggle = () => {
        const { location, history } = this.props;

        history.push({
            pathname: location.pathname
        });
    };

    render() {
        return <Modal isOpen={true} toggle={this.handleToggle} size="lg">
            <ModalHeader toggle={this.handleToggle}>Nápoveda</ModalHeader>
            <ModalBody>
                <Flex>
                    <Flex as={Nav} flexDirection="column">
                        <NavItem><StyledNavLink onClick={() => this.handleTab('basics')}>Základné informácie o systéme SearchIn</StyledNavLink></NavItem>
                        <NavItem><StyledNavLink onClick={() => this.handleTab('start')}>Začíname pracovať</StyledNavLink></NavItem>
                        <NavItem><StyledNavLink onClick={() => this.handleTab('projects')}>Práca s vlastnými projektami</StyledNavLink></NavItem>
                        <NavItem><StyledNavLink onClick={() => this.handleTab('new-project')}>Tvorba nového projektu</StyledNavLink></NavItem>
                        <NavItem><StyledNavLink onClick={() => this.handleTab('edit-project')}>Úprava projektu</StyledNavLink></NavItem>
                        <NavItem><StyledNavLink onClick={() => this.handleTab('query')}>Práca s vlastným reťazcom</StyledNavLink></NavItem>
                        <NavItem><StyledNavLink onClick={() => this.handleTab('sources')}>Výber zdroja</StyledNavLink></NavItem>
                        <NavItem><StyledNavLink onClick={() => this.handleTab('calendar')}>Práca s kalendárom</StyledNavLink></NavItem>
                        <NavItem><StyledNavLink onClick={() => this.handleTab('set-dates')}>Ručné nastavenie dátumu</StyledNavLink></NavItem>
                        <NavItem><StyledNavLink onClick={() => this.handleTab('articles')}>Práca s článkami</StyledNavLink></NavItem>
                        <NavItem><StyledNavLink onClick={() => this.handleTab('history')}>História vyhľadávania</StyledNavLink></NavItem>
                        <NavItem><StyledNavLink onClick={() => this.handleTab('new-query')}>Tvorba vyhľadávacích reťazcov</StyledNavLink></NavItem>
                        <NavItem><StyledNavLink onClick={() => this.handleTab('simple-query')}>Jednoslovné dotazy</StyledNavLink></NavItem>
                        <NavItem><StyledNavLink onClick={() => this.handleTab('multiword-query')}>Vyhľadávanie kombinácií viacerých slov</StyledNavLink></NavItem>
                        <NavItem><StyledNavLink onClick={() => this.handleTab('field-query')}>Vyhľadávanie v konkrétnom databázovom poli</StyledNavLink></NavItem>
                        <NavItem><StyledNavLink onClick={() => this.handleTab('advanced-query')}>Kombinovanie operátorov do komplexných reťazcov</StyledNavLink></NavItem>
                    </Flex>
                    {'basics' === this.state.tab && <Flex flex={1} flexDirection="column">
                        <h2>Základné informácie o systéme SearchIn</h2>

                        <StyledImg src={require('../assets/help/help01.png')}/>
                        <p>Vyhľadávací systém SearchIn umožňuje vyhľadať články a prepisy relácií vo via ako 1 100 zdrojoch. Jeho výhodou je okrem komplexnosti dát aj priložený printscreen webových portálov a sken príslušnej strany pri tlačených médiách. Umožňuje tak komfortný a ucelený prehľad o mediálnych výstupoch.</p>
                    </Flex>}
                    {'start' === this.state.tab && <Flex flex={1} flexDirection="column">
                        <h2>Začíname pracovať</h2>

                        <p>Systém SearchIn ponúka po prihlásení prednastavený projekt a taktiež aktívne okno na zadanie vlastného vyhľadávacieho reťazca. Projekt zahŕňa zadané kľúčové slová, zvolené zdroje a samozrejme a vybrané dátumové obdobie za ktoré sa vyhľadávajú relevantné články.</p>
                        <StyledImg src={require('../assets/help/help02.png')}/>
                    </Flex>}
                    {'projects' === this.state.tab && <Flex flex={1} flexDirection="column">
                        <h2>Práca s vlastnými projektami</h2>

                        <p>Vlastný projekt aktivujete kliknutím na tlačidlo „Vyhľadať“.</p>
                        <StyledImg src={require('../assets/help/help03.png')}/>

                        <p>Zobrazí sa nastavený reťazec a súčasne systém vyhľadá zodpovedajúce články a súčasne zobrazí panel s grafmi. </p>
                        <StyledImg src={require('../assets/help/help04.png')}/>

                        <p>Grafy môžeme zobraziť kliknutím na šípku v pravej časti obrazovky. V prípade, že grafy zatiaľ neboli zvolené, môžeme si pridať na obrazovky grafy podľa vlastného výberu. K dispozícii sú „zmienky v čase“, „Top typy zdrojov“, „Top zdroje“, „Výskyt kľúčových slov“ a „Top autori“. Dátumové obdobie zvolených grafov zodpovedá dátumovému obdobiu projektu.</p>
                        <StyledImg src={require('../assets/help/help04_1.png')}/>

                        <p>Po zvolení si „Typu“ grafu sa automaticky doplní „Meno“ grafu.</p>
                        <StyledImg src={require('../assets/help/help04_2.png')}/>

                        <p>Stlačením tlačidla sa zvolený graf uloží a pridá na obrazovku.</p>
                        <StyledImg src={require('../assets/help/help04_3.png')}/>

                        <p>Rovnakým postupom môžeme pridať na obrazovku aj ďalšie grafy.</p>
                        <StyledImg src={require('../assets/help/help04_4.png')}/>

                        <p>V prípade, že je potrebné robiť na grafe nejaké zmeny, slúži k tomu tlačidlo v pravom hornom roku grafu. Toto tlačidlo slúži na zmenu typu grafu a taktiež na vlastné pomenovanie grafu.</p>
                        <StyledImg src={require('../assets/help/help04_5.png')}/>

                        <p>Ak potrebuje zobraziť graf na celej obrazovke, alebo ho uložiť či vytlačiť, slúži k tomu tlačidlo v tvare troch vodorovných pruhov v pravom hornom rohu grafu.</p>
                        <StyledImg src={require('../assets/help/help04_6.png')}/>

                        <p>Systém SearchIn umožňuje jednoduchú prácu s vygenerovanými grafmi vrátane odstránenia grafu.</p>
                        <StyledImg src={require('../assets/help/help04_7.png')}/>

                        <p>Ak už nechceme zobrazovať grafy, ale chcem pracovať s vyhľadanými článkami, na skrytie grafov slúži tlačidlo vpravo hore.</p>
                        <StyledImg src={require('../assets/help/help04_8.png')}/>
                    </Flex>}
                    {'new-project' === this.state.tab && <Flex flex={1} flexDirection="column">
                        <h2>Tvorba nového projektu</h2>

                        <p>Systém SearchIn umožňuje pracovať s viacerými vlastnými projektami. Ich zoznam zobrazíme kliknutím na tlačidlo „PROJEKTY“</p>
                        <StyledImg src={require('../assets/help/help05.png')}/>
                        <p>Systém SearchIn zobrazí všetky projekty, ako aj možnosť pridávania nových projektov.</p>
                        <StyledImg src={require('../assets/help/help06.png')}/>
                        <p>Po jeho stlačení systém SearchIn otvorí formulár, do ktorého je potrebné zadať NÁZOV PROJEKTU, VYHĽADÁVACÍ REŤAZEC, zvoliť si ZDROJE a DÁTUM.</p>
                        <StyledImg src={require('../assets/help/help07.png')}/>
                        <p>Po vyplnení všetkých polí nový projekt uložíte stlačením tlačidla „Uložiť projekt“.</p>
                    </Flex>}
                    {'edit-project' === this.state.tab && <Flex flex={1} flexDirection="column">
                        <h2>Úprava projektu</h2>

                        <p>Na úpravu vlastného projektu slúži tlačidlo „Upraviť projekt“.</p>
                        <StyledImg src={require('../assets/help/help08.png')}/>
                        <p>Systém umožňuje meniť NÁZOV PROJEKTU, zmeniť VYHĽADÁVACÍ REŤAZEC, definovať ZDROJE a taktiež zmeniť DÁTUM, čiže časové okno vyhľadávania.</p>
                        <StyledImg src={require('../assets/help/help09.png')}/>
                        <p>Po zadaní všetkých potrebných údajov stlačením tlačidla „Uložiť projekt“ uložíte zadané zmeny.</p>
                    </Flex>}
                    {'query' === this.state.tab && <Flex flex={1} flexDirection="column">
                        <h2>Práca s vlastným reťazcom</h2>

                        <p>Po prihlásení do systému SearchIn zadajte vami hľadaný výraz do horného okna.</p>
                        <StyledImg src={require('../assets/help/help10.png')}/>
                    </Flex>}
                    {'sources' === this.state.tab && <Flex flex={1} flexDirection="column">
                        <h2>Výber zdroja</h2>

                        <p>Systém SearchIn umožňuje vybrať si konkrétny zdroj, resp. skupinu zdrojov, v ktorej chcete hľadať jednoduchým kliknutím na tlačidlo "Zdroje".</p>
                        <p>K dispozícii je šesť skupín zdrojov. Každá skupina sa dá samostatne zapnúť alebo vypnúť.</p>
                        <StyledImg src={require('../assets/help/help12.png')}/>
                        <p>Ak hľadáte konkrétny zdroj, stačí zadať časť z názvu zdroja do vyhľadávacieho okna a systém SearchIn zobrazí všetky zdroje, ktoré zadané znaky obsahujú.</p>
                        <StyledImg src={require('../assets/help/help11.png')}/>
                        <p>Zakliknutím políčka pred názvom zdroja aktivujete jeho výber. Systém SearchIn zobrazuje vľavo hore celkový počet vybraných zdrojov. Výber aktivujete tlačidlom „potvrdiť“.</p>
                        <StyledImg src={require('../assets/help/help13.png')}/>
                        <p>V prípade, že si nezvolíte žiadny zdroj, systém SearchIn automaticky prehľadá všetky dostupné zdroje.</p>
                    </Flex>}
                    {'calendar' === this.state.tab && <Flex flex={1} flexDirection="column">
                        <h2>Práca s kalendárom</h2>

                        <p>Kalendár slúži na výber časového intervalu v ktorom chcete vyhľadávať zvolený reťazec.</p>
                        <StyledImg src={require('../assets/help/help14.png')}/>
                        <p>Systém SearchIn je nastavený tak, že sa automaticky navolí posledný ukončený deň. Ak potrebujete zmeniť interval vyhľadávania, kliknite na tlačidlo s ikonou kalendár na obrazovke hore.</p>
                        <StyledImg src={require('../assets/help/help15.png')}/>
                        <p>Kalendár ponúka viacero prednastavených možností vyhľadávania ako napríklad: dnes, včera, posledných 7 dní atď. Ak kliknete napríklad na „Posledných 7 dní“ systém automaticky vysvieti posledných sedem dní v kalendári. Výber potvrdíte stlačením tlačidla „Potvrdiť“.</p>
                    </Flex>}
                    {'set-dates' === this.state.tab && <Flex flex={1} flexDirection="column">
                        <h2>Ručné nastavenie dátumu</h2>

                        <p>Dátum vyhľadávania od určeného dňa a do určeného dňa môžete nastaviť aj ručne.</p>
                        <p>Prvý deň, od ktorého sa bude vyhľadávať zvolený reťazec, zadávate v ľavom okne (Dátum od). Najskôr v záhlaví tabuľky kliknete na rok a systém ponúkne všetky roky od roku 1990. Kliknutím na požadovaný rok ho aktivujete.</p>
                        <StyledImg src={require('../assets/help/help16.png')}/>
                        <p>Kliknutím na mesiac sa zobrazí ponuka všetkých mesiacov. Po kliknutí na deň v mesiaci sa zobrazí ponuka so všetkými dňami pre daný mesiac. Rovnako sa nastavuje aj "Dátum do".</p>
                        <p>Po zvolení dátumového rozsahu výber potvrdíte stlačením tlačidla "Potvrdiť".</p>
                        <p>Systém SearchIn je tak pripravený na vyhľadávanie. Po stlačení tlačidla "Vyhľadať" zobrazí všetky články, ktoré spĺňajú vami zvolené kritériá.</p>
                    </Flex>}
                    {'articles' === this.state.tab && <Flex flex={1} flexDirection="column">
                        <h2>Práca s článkami</h2>

                        <p>Systém SearchIn vyhľadá články podľa vami zvolených kritérií. Ich počet nájdete pod zadávacím oknom vľavo.</p>
                        <StyledImg src={require('../assets/help/help17.png')}/>
                        <p>Zoznam hlavičiek článkov sa zobrazí v ľavom okne. Pravé okno slúži na zobrazenie detailu článku.</p>
                        <p>Ak kliknete na vybraný článok v zozname, zobrazí sa v pravom okne celý v médiách zverejnený text.</p>
                        <StyledImg src={require('../assets/help/help18.png')}/>
                        <p>Vybrané články zaradíme do exportu zakliknutím políčka vľavo od hlavičky článku.</p>
                        <StyledImg src={require('../assets/help/help19.png')}/>
                        <p>Na exportovanie vybraných článkov slúži tlačidlo "Export".</p>
                        <StyledImg src={require('../assets/help/help20.png')}/>
                        <p>Jeho súčasťou je v zelenom krúžku číslo, ktoré zobrazuje celkový počet vybraných článkov do exportu.</p>
                        <StyledImg src={require('../assets/help/help21.png')}/>
                        <p>Po jeho stlačení systém SearchIn umožní cez tlačidlá v pravom hornom rohu vybrať typ exportu (html, rtf a csv) a počet článkov v exporte (10, 20, 50, 100 a 200). Stlačením tlačidla "Export" systém vygeneruje požadovaný typ exportu.</p>
                    </Flex>}
                    {'history' === this.state.tab && <Flex flex={1} flexDirection="column">
                        <h2>História vyhľadávania</h2>

                        <p>Systém SearchIn si pamätá históriu vyhľadávania.</p>
                        <StyledImg src={require('../assets/help/help22.png')}/>
                        <p>Po stlačení tlačidla „História vyhľadávania“ sa otvorí história vášho vyhľadávania. Zobrazené sú hľadané kľúčové slová, počet vybraných zdrojov, dátumové rozpätie a tiež aj počet nájdených výstupov.</p>
                        <StyledImg src={require('../assets/help/help23.png')}/>
                        <p>Môžete sa tak vrátiť k vyhľadávaniam, ktoré ste urobili v minulosti jednoduchým stlačením tlačidla „vyhľadať“. </p>
                        <StyledImg src={require('../assets/help/help24.png')}/>
                    </Flex>}
                    {'new-query' === this.state.tab && <Flex flex={1} flexDirection="column">
                        <h2>Tvorba vyhľadávacích reťazcov</h2>

                        <p>Pri tvorbe vyhľadávacieho reťazca je možné používať Boolove operátory AND, OR resp. AND NOT. Ďalšie operátory, ktoré je možné použiť sú "+" a "-". Operátor + znamená, že daný výraz musí byť medzi nájdenými výrazmi. Operátor - znamená, že daný výraz nesmie byť medzi nájdenými výrazmi.</p>
                        <p>Taktiež je možné vytvoriť skupiny slov pomocou ().</p>
                    </Flex>}
                    {'simple-query' === this.state.tab && <Flex flex={1} flexDirection="column">
                        <h2>Jednoslovné dotazy</h2>

                        <pre>slovo</pre>
                        <p>výsledkom je výpis článkov, ktoré obsahujú vyhľadávaný termín v pôvodnej aj vyskloňovanej alebo vyčasovanej podobe. Neprihliada sa ani na veľké či malé písmená.</p>
                        <pre>"fráza" – presné vyhľadávanie</pre>
                        <p>výsledkom sú články, ktoré obsahujú presnú podobu slova, alebo slovného spojenia aj so zohľadnením veľkých a malých písmen</p>
                        <p><strong>Upozornenie:</strong> Operátorom nie sú typografické úvodzovky – horné a dolné.</p>
                        <pre>slov*</pre>
                        <p>operátor * nahrádza ľubovoľný počet znakov nasledujúcich za koreňom slova.</p>
                        <p>Výsledkom sú články ktoré obsahujú pojmy začínajúce znakmi slov – čiže napr. <em>slovo</em>, <em>Slovák</em>, <em>slovenčina</em>, <em>slovník</em> atď. Využitie je vhodné pri hľadaní vlastných mien a priezvisk.</p>
                        <pre>sl?v?</pre>
                        <p>operátor ? nahrádza práve jeden znak kdekoľvek v slove, v jednom slove môže byť použitý viackrát.</p>
                        <p>Výsledkom sú články obsahujúce napr. <em>slovo</em>, <em>slová</em>, <em>sláva</em>, <em>sliva</em></p>
                        <p><strong>Upozornenie:</strong> Použitie presného vyhľadávania s pomocou úvodzoviek, sa logicky vylučuje s použitím operátorov * a ?.</p>
                        <pre>slovo~2</pre>
                        <p>operátor ~ (ctrl+alt+1) nahrádza v slove počet znakov definovaný za operátorom .</p>
                        <p>Výsledkom sú články, ktoré obsahujú napr. <em>slová</em>, <em>olovo</em>, <em>skoro</em>, <em>sloni</em>, ...</p>

                        <h2>Príklady</h2>
                        <p>Hľadáme napríklad meno Robert, no nevieme či niekde nie je napísané ako Róbert. V tomto prípade zadáme R?bert a systém Searchin nájde všetkých Robertov aj Róbertov.</p>
                        <p>Hľadáme napríklad slovo spoločnosť. Stačí zadať spoločnos* a systém nájde všetky slová, kde sa vyskytuje základ slova spoločnos. Je to výhodné najmä pri slovách, kde sa mení základ slova a vyhľadávací systém nedokáže korektne skloňovať dané slovo. Napríklad spoločnosť/spoločnosti, voľba/volieb atď.</p>
                    </Flex>}
                    {'multiword-query' === this.state.tab && <Flex flex={1} flexDirection="column">
                        <h2>Vyhľadávanie kombinácií viacerých slov</h2>

                        <h2>Operátory OR, AND, NOT</h2>
                        <h3>Operátor OR (|) (ctrl+alt+w)</h3>
                        <p>je predvolený operátor systému a teda nie je potrebné ho zadávať</p>
                        <pre>slovo1 slovo2</pre>
                        <p>Výsledkom sú články, ktoré obsahujú slovo1 alebo slovo 2 (v základnej, vyskloňovanej alebo vyčasovanej podobe)</p>
                        <h3>operátor AND (+)</h3>
                        <pre>+slovo1 +slovo2 (identické so zadaním slovo1 AND slovo2)</pre>
                        <p>Výsledkom sú články, ktoré obsahujú slovo1, aj slovo2</p>
                        <pre>slovo1 +slovo2 (identické so zadaním slovo2 OR slovo1)</pre>
                        <p>Výsledkom sú články, ktoré obsahujú slovo2 a môžu obsahovať slovo1, ktoré bude tiež podfarbené, články s oboma slovami budú mať vyššiu relevanciu</p>
                        <h3>operátor NOT (-)</h3>
                        <pre>+slovo1 -slovo2 (identické so zadaním slovo1 NOT slovo2)</pre>
                        <p>Výsledkom sú články, ktoré obsahujú slovo2 a neobsahujú slovo1</p>
                        <pre>"slovo1 slovo2" – presné vyhľadávanie</pre>
                        <p>výsledkom sú články, ktoré obsahujú presnú podobu slovného spojenia aj so zohľadnením veľkých a malých písmen</p>
                        <pre>"slovo1 slovo2"~3</pre>
                        <p>Operátor ~ určuje v prípade frázy maximálnu prípustnú vzdialenosť medzi slovami</p>
                        <p>Výsledkom sú články, ktoré obsahujú slovo1 a slovo2, pričom medzi nimi nie sú viac, ako 3 iné slová</p>
                        <pre>slovo1 slovo2ˆ2</pre>
                        <p>operátor ˆ (ctrl+alt+3) zvyšuje váhu slova pri zoraďovaní výsledkov vyhľadávania na základe hodnoty za operátorom. V prípade že nie je tento operátor použitý, slová majú rovnakú váhu a sú prioritizované podľa poradia vo vyhľadávacom reťazci. Hodnota v intervale 0 až 1 váhu slova znižuje, hodnota vyššia ako 1 ju zvyšuje.</p>
                        <pre>slovo1 NOT (slovo2 slovo3)</pre>
                        <p>() vytvorí skupinu slov, na ktoré sa vzťahuje operácia</p>
                        <p>Výsledkom sú články, ktoré obsahujú slovo1 ale neobsahujú ani slovo2, ani slovo3</p>

                        <h3>Príklady</h3>
                        <p>Hľadáme výraz Národná rada Slovenskej republiky. Môžeme ho zadať viacerými spôsobmi:</p>
                        <p><em>Národná rada Slovenskej republiky</em> - pri takomto zadaní systém SearchIn nájde všetky články, kde sa vyskytuje aspoň jedno zo slov (systém pri takomto zadaní nerozlišuje veľké a malé písmená). Medzera medzi slovami je ekvivalentom funkcie OR.</p>

                        <p><em>Národná AND rada AND Slovenskej AND republiky</em> - pri takomto zadaní systém SearchIn nájde všetky články, kde sa vyskytuje niektorá z kombinácií napríklad národná rada a Slovenskej republiky, no nemusia byť nutne vedľa seba.</p>

                        <p>Pri zadaní <em>+Národná +rada +Slovenskej +republiky</em> systém SearchIn nájde všetky články, kde sa vyskytuje niektorá z kombinácií napríklad národná rada a Slovenskej republiky, no nemusia byť nutne vedľa seba.</p>
                        <p>Ak hľadáme presný výraz, je potrebné zadať frázu do úvodzoviek <em>"Národná rada Slovenskej republiky"</em>. Systém SearchIn vtedy vyhľadá všetky články, kde sa vyskytuje celé slovné spojenie.</p>

                        <p>Hľadáme výraz Národná rada no nie Slovenskej republiky. Dopyt môžeme zapísať ako:</p>
                        <p><em>(Národná rada) AND NOT (Slovenskej republiky)</em>. Systém SearchIn vyhľadá všetky články, kde sa vyskytuje národná resp. rada a nevyskytuje sa Slovenská resp. republika.</p>
                        <p>Alternatívne zadanie je <em>+Národná +rada -Slovenskej -republiky</em>. Systém SearchIn vyhľadá všetky články, kde sa vyskytujú slová národná a rada, no nemusia byť vedľa seba a nevyskytujú sa slová Slovenskej a republiky.</p>
                    </Flex>}
                    {'field-query' === this.state.tab && <Flex flex={1} flexDirection="column">
                        <h2>Vyhľadávanie v konkrétnom databázovom poli</h2>

                        <p>Vyhľadávanie podľa autora:</p>
                        <pre>author: Novák</pre>
                        <p>Výsledkom sú články všetkých autorov s priezviskom Novák</p>
                        <p>Pri vyhľadávaní v poli author je možné použiť aj operátor *</p>
                        <pre>author: "Peter Novák"</pre>
                        <p>Výsledkom sú len články autora Petra Nováka</p>

                        <p>Vyhľadávanie podľa servisu:</p>
                        <pre>service: šport</pre>
                        <p>Výsledkom sú správy, ktoré majú ako servis definovaný šport</p>
                        <p>Pri vyhľadávaní v poli service je možné použiť aj operátor *</p>
                        <p>V prípade viacslovného názvu servisu je potrebné použiť úvodzovky</p>
                        <pre>service: "ľadový hokej"</pre>
                        <p><strong>Upozornenie:</strong> Vyhľadávanie v databázovom poli je viazané len na prvý výraz za definíciou poľa, preto musí byť viacslovné spojenie v úvodzovkách. Ďalšie slovo bez úvodzoviek je považované za nový vyhľadávaný výraz a použije sa nad všetkými poliami s východiskovým operátorom OR.</p>
                    </Flex>}
                    {'advanced-query' === this.state.tab && <Flex flex={1} flexDirection="column">
                        <h2>Kombinovanie operátorov do komplexných reťazcov</h2>

                        <p>Jednotlivé operátory je možné pri tvorbe dotazu vzájomne kombinovať:</p>
                        <pre>B?d?r NOT (Kaliňák, Fico)</pre>
                        <p>Výsledkom budú všetky články ktoré obsahujú slová ako bodor, Bödör, bedár, bador atď. a súčasne neobsahujú mená Kaliňák resp. Fico.</p>

                        <pre>service: prominenti +Gott</pre>
                        <p>Výsledkom budú všetky články o Gottovi ktoré boli zaradené do servisu prominenti</p>

                        <pre>((Modrý Zelený) AND ("Alfa Centauri" "Centauri Invest" "Alfa Consulting & Development"))</pre>
                        <p>Výsledkom budú všetky články, kde sa budú vyskytovať Modrý alebo Zelený a súčasne aspoň jedna z definovaných podmienok Alfa Centauri, Centauri Invest alebo Alfa Consulting & Development.</p>
                    </Flex>}

                </Flex>
            </ModalBody>
            <ModalFooter>
                <Button color="secondary" onClick={this.handleToggle}>Zatvoriť</Button>
            </ModalFooter>
        </Modal>
    }
}

export default withRouter(Help);
