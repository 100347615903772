//@flow

import * as React from 'react';

import {Flexbox} from "../../../components";

export default class extends React.PureComponent {

    render() {
        return <Flexbox xs={{col: null, 'align-items': 'center', text: 'center', 'justify-content': 'center'}}>
            Vášmu vyhľadávaniu nezodpovedá žiaden článok. <br/>Skúste upraviť vyhľadávaný reťazec, výber zdrojov a časové okno.
        </Flexbox>
    }
}