import * as React from 'react';
import {connect} from 'react-redux';
import {withRouter} from "react-router-dom";
import {logout} from "../../actions";

class Logout extends React.PureComponent {

    componentDidMount(): void {
        const {logout} = this.props;

        logout();
    }

    render() {
        return null
    }
}

Logout = connect(
    (state, props) => {
        return {}
    },
    (dispatch, props) => {
        return {
            logout: () => {
                dispatch(logout());
                props.history.push('/login')
            }
        }
    }
)(Logout);

export default withRouter(Logout);
