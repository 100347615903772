//@flow

import * as React from 'react';
import {connect} from "react-redux";
import {formValueSelector, change} from "redux-form";
import gql from "graphql-tag";

import Coverflow from "../../../components-v3/Coverflow/Coverflow";
import {Cover} from "../../../components-v3/Coverflow/StyledContainers";
import {Clipping} from "../../../components-v3";
import {query} from "../../../actions";

class ArticlesCoverflow extends React.Component<ExportProps> {

    constructor(props) {
        super(props);

        this.state = {
            articles: []
        }
    }

    componentDidMount() {
        const { selected, getArticle } = this.props;

        selected.map(article => getArticle(article)
            .then(response => this.setState({articles: {
                ...this.state.articles,
                [response.data.article.id]: response
            }}))
        )
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { selected, getArticle } = this.props;

        selected.map(article => !this.state.articles[article] && getArticle(article)
            .then(response => this.setState({articles: {
                    ...this.state.articles,
                    [response.data.article.id]: response
                }}))
        )
    }

    handleRemove = (e, id) => {
        e.stopPropagation();

        const { selected, setArticle } = this.props;

        setArticle(selected.filter(article => article !== id));
    };

    render() {
        const { selected, onSelect } = this.props;

        return <Coverflow
                displayQuantityOfSide={4}
                navigation={false}
                enableHeading={true}
                active={0}
            >
            {selected.map(article => <Cover key={article}
                                            as={Clipping}
                                            src={this.state.articles[article]?.data.article.clippings[0]?.url}
                                            type="large"
                                            alt={this.state.articles[article]?.data.article.title}
                                            actions={<>
                                                {/*<span className="fa fa-arrow-alt-circle-up text-success m-1"/>*/}
                                                {/*<span className="fa fa-arrow-alt-circle-down text-danger m-1"/>*/}
                                                <span className="fa fa-times-circle m-1" onClick={(e) => this.handleRemove(e, article)}/>
                                            </>}
                                            onClick={() => onSelect(this.state.articles[article]?.data.article)}
            />)}
        </Coverflow>
    }
}

ArticlesCoverflow = connect(
    (state, props) => {
        const selector = formValueSelector('export');
        return {
            selected: selector(state, 'articles')
        }
    },
    (dispatch, props) => ({
        setArticle: articles => dispatch(change('export', 'articles', articles)),
        getArticle: id => dispatch(query(gql`query Article($id: String!) {
            article(id: $id) {
                id
                title
                service
                author
                url
                content
                date
                page
                source {
                    name
                    sourceGroup {
                        id
                        name
                    }
                }
                clippings {
                    id
                    url
                }
                keywords
                score
            }
        }`, {
            id: id
        }))
    })
)(ArticlesCoverflow);

export default ArticlesCoverflow;
