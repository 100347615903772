//@flow

import * as React from 'react';
import Highcharts, {merge} from 'highcharts';
import HighchartsExporting from 'highcharts/modules/exporting';
import HighchartsWordcloud from 'highcharts/modules/wordcloud';
import HighchartsReact from 'highcharts-react-official';
import styled from 'styled-components';
import {Modal, ModalBody, ModalHeader} from "@bootstrap-styled/v4";
import {connect} from 'react-redux';

import UpdateChart from "../UpdateChart";
import {deleteChart, getCharts} from '../../../queries';

if (typeof Highcharts === 'object') {
    HighchartsExporting(Highcharts)
    HighchartsWordcloud(Highcharts)
}

class InitializedHighcharts extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            edit: false
        }
    }

    handleSettings = () => {
        this.setState({edit: !this.state.edit});
    };

    handleDelete = () => {
        const {deleteChart} = this.props;

        deleteChart();
    };

    render() {
        const {options, theme, chart, ...rest} = this.props;

        return <>
            <HighchartsReact highcharts={Highcharts}
                                options={merge({
                                    chart: {
                                        backgroundColor: theme['$seashell'],
                                        width: null
                                    },
                                    title: {
                                        align: 'left',
                                        margin: 30,
                                        x: 20
                                    },
                                    colors: [theme['$yellow-orange']],
                                    legend: {
                                        enabled: false
                                    },
                                    // responsive: {
                                    //     rules: [{
                                    //         condition: {
                                    //             maxWidth: 767
                                    //         },
                                    //         chartOptions: {
                                    //         }
                                    //     }]
                                    // },
                                    exporting: {
                                        buttons: {
                                            contextButton: {
                                                menuItems: ['printChart', 'separator', 'downloadPNG', 'downloadJPEG', 'downloadPDF', 'downloadSVG', 'separator', 'label'],
                                                theme: {
                                                    fill: theme['$seashell']
                                                }
                                            },
                                            settingsButton: {
                                                symbol: 'url(' + require('../../../assets/svg/ikonka nastavenia 2.png') + ')',
                                                symbolSize: 16,
                                                symbolX: 20,
                                                symbolY: 20,
                                                theme: {
                                                    fill: theme['$seashell']
                                                },
                                                onclick: this.handleSettings
                                            }
                                        },
                                        menuItemDefinitions: {
                                            label: {
                                                onclick: this.handleDelete,
                                                text: 'Vymazať graf'
                                            }
                                        }
                                    }
                                }, options)}
                                {...rest}
            />
            {this.state.edit && <Modal isOpen={true} toggle={this.handleSettings}>
                <ModalHeader toggle={this.handleSettings}>Nastavenia</ModalHeader>
                <ModalBody>
                    <UpdateChart chart={chart}/>
                </ModalBody>
            </Modal>}
        </>
    }
}

InitializedHighcharts = connect(
    (state, props) => ({}),
    (dispatch, props) => {
        return {
            deleteChart: () => dispatch(deleteChart({id: props.chart.id}))
                .then(() => dispatch(getCharts({project: props.chart.project.id})))
        }
    }
)(InitializedHighcharts);

export default styled(InitializedHighcharts).attrs(props => ({theme: props.theme}))``;
